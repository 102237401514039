import { MenuItem, MenuList, Typography } from "@mui/material";
import { DimensionField, Grouping } from "../../../../../shared/reporting/api/biClient.types";
import { defaultDateGrouping, getDateGroupingLabel } from "../../utils/captionsHelper";

interface Props {
  field: DimensionField;
  updateGrouping: (newGrouping: Grouping | undefined) => void;
}

const DateGroupingOptionsList = ({ field, updateGrouping }: Props) => {
  return (
    <MenuList sx={{ p: 0 }}>
      {defaultDateGrouping.map((g, i) => (
        <MenuItem key={`${g}${i}`} selected={field.config.grouping === g} onClick={() => updateGrouping(g)}>
          <Typography variant="caption">{getDateGroupingLabel(g)}</Typography>
        </MenuItem>
      ))}
    </MenuList>
  );
};

export default DateGroupingOptionsList;
