import { Box, Typography } from "@mui/material";
import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import { FieldConfiguration } from "../../../../../shared/reporting/api/biClient.types";
import {
  AreaItemType,
  DimensionField,
  DimensionFieldType,
  ItemDataType,
  MeasureField,
  ReportField,
} from "../../../../../shared/reporting/api/biClient.types.ts";
import { selectDimensions } from "../../../../store/metaDataSlice";
import AreaFieldItem from "../../common/fields/areaFields/AreaFieldItem";
import DropFieldContainer from "../../common/fields/DropFieldContainer";
import { FieldItemProps } from "../../common/fields/types/areaFiledItem.types";
import { DraggableFieldType, DraggableMetaType, FieldWrapper } from "../../common/fields/types/dropField.types.ts";
import { createTabularField } from "../../common/utilities/createFields";
import { extractGuid, extractMeta } from "../../common/utilities/dropFieldContainerHelper";
import { formatMeasureCaption } from "../../pivot/table/measures/helper";
import MeasureItem from "../../pivot/table/measures/MeasureItem";
import { ConditionFieldsArea } from "../../pivot/types";
import { ShowFieldOptionsSettings } from "../../Types";
import { formatDimensionFieldCaptions, getGroupingCaption } from "../../utils/formatDimensionFieldCaptions";
import { getDimensionFields, isMeasureConfigurationValid } from "../../utils/isConfigurationValid";
import { isDimension, isMeasure } from "../../utils/isMeasure.ts";
import { getCustomConditionsChanges } from "../../utils/MeasureUtils";
import { FieldsArea } from "../contexts/FieldsStateContext";
import { canDropItem } from "../utilities/fieldsDropContainerHelper.ts";
import { referenceDateFields } from "../utilities/referenceDateFields.ts";
import GeneralFieldOptionPopup from "./GeneralFieldOptionPopup";
import MeasureFieldOptionPopup from "./MeasureFieldOptionPopup";

interface Props {
  fieldsArea: FieldsArea;
  conditionsArea: ConditionFieldsArea;
}

export default function FieldsDropContainer({ fieldsArea, conditionsArea }: Props) {
  const storedDimensions = useSelector(selectDimensions);

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [optionMeasureField, setOptionMeasureField] = React.useState<MeasureField>();
  const [optionDimensionField, setOptionDimensionField] = React.useState<DimensionField>();
  const [currentField, setCurrentField] = React.useState<ReportField | null>(null);

  const fieldsAreaRef = React.useRef(fieldsArea);
  fieldsAreaRef.current = fieldsArea;

  const dimensions = React.useMemo(() => {
    return storedDimensions
      .filter((d) => d.type !== ItemDataType.Numeric)
      .sort((a, b) => a.caption.localeCompare(b.caption));
  }, [storedDimensions]);

  React.useEffect(() => {
    if (isMeasure(currentField)) {
      const field = fieldsArea.values.find((v) => v.config.guid === optionMeasureField?.config.guid);
      if (isMeasure(field)) {
        setOptionMeasureField(field);
      }
    }
  }, [fieldsArea.values, optionMeasureField, currentField]);

  React.useEffect(() => {
    if (isDimension(currentField)) {
      const field = fieldsAreaRef.current.values.find((v) => v.meta.name === optionDimensionField?.meta.name);
      if (isDimension(field)) {
        setOptionDimensionField(field);
      }
    }
  }, [fieldsArea.values, optionDimensionField, currentField]);

  const showMeasureOptions = React.useCallback(
    (field: ReportField, settings: ShowFieldOptionsSettings<MeasureField>) => {
      if (!isMeasure(field) || !isMeasure(settings.field)) return;
      setOptionDimensionField(undefined);
      setOptionMeasureField(settings.field);
      setAnchorEl(settings.ref);
      setCurrentField(field);
    },
    []
  );

  const showDimensionOptions = React.useCallback(
    (field: DimensionField, settings: ShowFieldOptionsSettings<DimensionField>) => {
      setOptionMeasureField(undefined);
      setOptionDimensionField(settings.field);
      setAnchorEl(settings.ref);
      setCurrentField(field);
    },
    []
  );

  const handleMeasureSaveChanges = React.useCallback(
    (changes: Partial<FieldConfiguration>, causeClosing: boolean) => {
      if (currentField === null || currentField.type !== DimensionFieldType.MEASURE) return;
      fieldsAreaRef.current.updateMeasureFieldConfig(currentField, changes);
      if (causeClosing) setAnchorEl(null);
    },
    [currentField]
  );

  const applyAllowCustomConditions = React.useCallback(
    (useCustomConditions: boolean) => {
      if (currentField !== null && currentField.type === DimensionFieldType.MEASURE) {
        fieldsAreaRef.current.updateMeasureFieldConfig(currentField, getCustomConditionsChanges(useCustomConditions));
      }
    },
    [currentField]
  );

  const measureCaptionColor = React.useCallback(
    (value: MeasureField) => {
      const valid = isMeasureConfigurationValid(
        conditionsArea.values,
        getDimensionFields(fieldsArea.values),
        dimensions,
        value
      );
      return valid.valid ? undefined : "error.main";
    },
    [conditionsArea.values, fieldsArea.values, dimensions]
  );

  const handleDimensionFieldCaptions = useCallback((field: DimensionField) => {
    const getSubCaptions = referenceDateFields.includes(field.meta.name) ? getGroupingCaption : undefined;
    return formatDimensionFieldCaptions(field, getSubCaptions);
  }, []);

  const handleMeasureFormatCaption = React.useCallback(
    (field: ReportField) => {
      if (isMeasure(field)) {
        return formatMeasureCaption(field, measureCaptionColor(field));
      } else if (isDimension(field)) {
        // eslint-disable-next-line no-debugger
        debugger;
        return handleDimensionFieldCaptions(field);
      }

      return undefined;
    },
    [handleDimensionFieldCaptions, measureCaptionColor]
  );

  const handleCanDropItem = React.useCallback((item: FieldWrapper<DraggableFieldType> | DraggableMetaType) => {
    return canDropItem(item, fieldsAreaRef.current.values);
  }, []);

  const createItem = React.useCallback(
    (props: FieldItemProps<ReportField>) => {
      if (isMeasure(props.field)) {
        const field = props.field;
        return (
          <MeasureItem
            field={field}
            fields={dimensions}
            globalConditions={conditionsArea.values}
            showConditionParameter
            formatCaption={handleMeasureFormatCaption}
            canBeRemoved
            onRemoveItem={() => fieldsAreaRef.current.removeItem(field)}
            updateMeasure={(_, changes) => fieldsAreaRef.current.updateMeasureFieldConfig(field, changes)}
            onShowOptions={(settings) => showMeasureOptions(field, settings)}
          />
        );
      } else if (isDimension(props.field)) {
        const field = props.field;
        return (
          <AreaFieldItem
            field={field}
            canBeRemoved
            onRemoveItem={() => fieldsAreaRef.current.removeItem(field)}
            formatCaption={handleDimensionFieldCaptions}
            onShowOptions={(settings) => showDimensionOptions(field, settings)}
          />
        );
      }
      return <></>;
    },
    [
      dimensions,
      conditionsArea.values,
      handleMeasureFormatCaption,
      showMeasureOptions,
      showDimensionOptions,
      handleDimensionFieldCaptions,
    ]
  );

  const handleRemove = React.useCallback(() => {
    setAnchorEl(null);
    if (currentField === null) {
      return;
    }
    fieldsAreaRef.current.removeItem(currentField);
  }, [currentField]);

  const closeMeasurePopup = React.useCallback(() => {
    setOptionMeasureField(undefined);
    setAnchorEl(null);
  }, []);

  const closeDimensionPopup = React.useCallback(() => {
    setOptionDimensionField(undefined);
    setAnchorEl(null);
  }, []);

  const handleUpdateConfiguration = useCallback(
    (changes: Partial<FieldConfiguration>, causeClosing: boolean) => {
      if (!isDimension(currentField)) {
        return;
      }
      fieldsAreaRef.current.updateDimensionFieldConfig(currentField, changes);
      if (causeClosing) {
        setAnchorEl(null);
      }
    },
    [currentField]
  );

  const handleDimensionFieldCustomLabelChange = useCallback(
    (name: string | undefined) => {
      if (!isDimension(currentField)) {
        return;
      }
      fieldsAreaRef.current.updateDimensionFieldConfig(currentField, { customLabel: name });
    },
    [currentField]
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: ".5rem",
      }}
    >
      <Typography variant="subtitle2" color={(theme) => theme.palette.text.primary}>
        Table Fields
      </Typography>
      <DropFieldContainer
        areaFieldType={AreaItemType.FIELDS}
        acceptedDropTypes={[AreaItemType.DIMENSION, AreaItemType.MEASURE, AreaItemType.CONDITIONS]}
        fields={fieldsArea.values}
        canDropItem={handleCanDropItem}
        renderListItem={createItem}
        getKeyValue={getKeyValue}
        onAddItem={fieldsArea.addItem}
        onRemoveItem={fieldsArea.removeItem}
        onMoveItem={fieldsArea.moveItem}
        createListItem={createTabularField}
      />
      {anchorEl && optionMeasureField && (
        <MeasureFieldOptionPopup
          anchorEl={anchorEl}
          measure={optionMeasureField}
          cancel={closeMeasurePopup}
          saveChanges={handleMeasureSaveChanges}
          saveAllowCustomConditions={applyAllowCustomConditions}
          onRemove={handleRemove}
        />
      )}

      {anchorEl && optionDimensionField && (
        <GeneralFieldOptionPopup
          anchorEl={anchorEl}
          field={optionDimensionField}
          cancel={closeDimensionPopup}
          onRemove={handleRemove}
          onUpdateConfiguration={handleUpdateConfiguration}
          onUpdateCustomLabel={handleDimensionFieldCustomLabelChange}
        />
      )}
    </Box>
  );
}

function getKeyValue(field: FieldWrapper<DraggableFieldType> | DraggableMetaType) {
  const guid = extractGuid(field);
  if (guid) {
    return guid;
  }
  const meta = extractMeta(field);
  if (meta) {
    return meta.name;
  }
  return "";
}
