import { ReportField } from "../../../../../shared/reporting/api/biClient.types";

export const updateTableField = (item: ReportField, changedValues: Partial<ReportField>, fields: ReportField[]) => {
  const field = fields.find((field) => field.config.guid === item.config.guid);
  if (field !== undefined) {
    Object.assign(field, changedValues);
  }
  return fields;
};

export const updateTableFieldsOrder = (fields: ReportField[], guids: string[]) => {
  return guids
    .map((guid) => {
      return fields.find((field) => field.config.guid === guid);
    })
    .filter((field): field is ReportField => !!field);
};
