import { Box, Collapse, Divider, Grid } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { ConditionDescriptor, ItemDataType } from "../../../../../../shared/reporting/api/biClient.types";
import { ChartOfAccountsItem } from "../../../../../api/biApi.types";
import ConditionFieldOptionPopup from "../../../pivot/table/conditions/ConditionFieldOptionPopup";
import { formatConditionCaption } from "../../../pivot/table/conditions/conditionsHelper";
import { ConditionField } from "../../../Types";
import { getIcon } from "../../fields/FieldItem.helper";
import { FilterFieldItem } from "../FilterFieldItem";
import CopyFilter from "./CopyFilter";
import FilterItem from "./FilterItem";

interface Props {
  condition: ConditionField;
  editable: boolean;
  chartOfAccountItems?: ChartOfAccountsItem[];
  onChanged: (changes: Partial<ConditionField>) => void;
  onRemove: () => void;
}

export const Filter = ({ condition, editable, onChanged, onRemove, chartOfAccountItems }: Props) => {
  const [optionsAnchorEl, setOptionsAnchorEl] = useState<HTMLElement | null>(null);
  const [expanded, setExpanded] = useState(false);

  const selectedValues = useMemo(() => {
    if (condition.config.filter?.values.length === 0) return [];
    return condition.meta.dictionary.filter((d) =>
      condition.config.filter.values.some((sv) => d[condition.meta.keyFieldName || ""] === sv)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [condition.config.filter?.values, condition.meta.dictionary]);

  const formatCaption = useCallback(
    (field: ConditionField) => {
      if (expanded) return undefined;
      return formatConditionCaption(field, false);
    },
    [expanded]
  );

  const handleClosePopup = useCallback(() => {
    setOptionsAnchorEl(null);
  }, []);

  const copyElement = useMemo(() => {
    if (editable) return null;
    return (
      <CopyFilter
        visible={true}
        values={condition.config.filter?.values || []}
        sx={{
          position: "relative",
          maxHeight: "30px",
        }}
      />
    );
  }, [editable, condition.config.filter?.values]);

  const onSave = useCallback(
    (filter: ConditionDescriptor) => {
      onChanged({ config: { ...condition.config, filter } });
      handleClosePopup();
    },
    [condition.config, onChanged, handleClosePopup]
  );

  return (
    <Box sx={{ flexDirection: "column", width: "100%" }}>
      <Grid container sx={{ position: "relative" }}>
        <FilterFieldItem
          field={condition}
          canBeRemoved={editable}
          hideOptions={!editable}
          formatCaption={formatCaption}
          onShowOptions={(settings) => setOptionsAnchorEl(settings.ref)}
          isLoading={condition.dictionary?.loading === true}
          startIcon={getIcon(condition.meta)}
          expandable
          onToggle={setExpanded}
          disableExpanding={selectedValues.length === 0}
          copyButton={copyElement}
        />
        {optionsAnchorEl && (
          <ConditionFieldOptionPopup
            anchorEl={optionsAnchorEl}
            field={condition}
            save={onSave}
            onRemoveItem={onRemove}
            canBeRemoved={editable}
            dictionary={condition.meta.dictionary}
            resizable={condition.meta.type !== ItemDataType.Date}
            readonlyCustomLabel={true}
            disableEqualitySelection={true}
            close={handleClosePopup}
          />
        )}
      </Grid>
      <Collapse orientation="vertical" in={expanded}>
        <Divider />
        <Grid container sx={{ position: "relative", paddingLeft: 3.25, paddingRight: 2 }}>
          {chartOfAccountItems !== undefined &&
            selectedValues.map((item, i) => (
              <FilterItem
                key={i}
                chartOfAccountItems={chartOfAccountItems}
                conditionName={condition.meta.name}
                text={item[condition.meta.availableFields[0] || ""]}
                name={item[condition.meta.availableFields[1] || ""]}
              />
            ))}
        </Grid>
      </Collapse>
    </Box>
  );
};

export default Filter;
