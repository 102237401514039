import {
  GroupingField,
  Ordering,
  ReportField,
  TabularConfiguration,
  TabularGeneralSettings,
} from "../../../../../shared/reporting/api/biClient.types";
import { ColumnSorting } from "../../../../api/biApi.types";
import { ConditionField } from "../../Types";

export const createTabularDataRequest = (
  allConditions: ConditionField[],
  fields: ReportField[],
  sorting: ColumnSorting[],
  grouping: GroupingField[],
  settings: TabularGeneralSettings
) => {
  const result: TabularConfiguration = {
    conditions: allConditions.map((c) => ({ ...c.config, invalid: c.invalid })),
    fields: fields.map((f) => f.config),
    sort: sorting.map((s) => ({
      name: s.dimensionName,
      ordering: s.sortAsc ? Ordering.Ascending : Ordering.Descending,
      caption: s.caption,
    })),
    grouping: grouping.map((g) => ({ name: g.name, hideSummaries: g.hideSummaries ?? false })),
    settings,
  };

  return result;
};
