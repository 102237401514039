import { List } from "@mui/material";
import { MeasureDescriptor } from "../../../../../../shared/reporting/api/biClient.types";
import { MeasuresStructure } from "../../../../../api/biApi.types";
import { buildTree } from "./MeasuresTree.Render";

interface Props {
  measures: MeasureDescriptor[];
  structure: MeasuresStructure;
  onShowOptions: (x: number, y: number, measure: MeasureDescriptor, group: string) => void;
}

const MeasuresTree = ({ measures, structure, onShowOptions }: Props) => {
  return <List sx={{ p: 0 }}>{buildTree(measures, structure, onShowOptions)}</List>;
};

export default MeasuresTree;
