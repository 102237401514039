import { Stack } from "@mui/material";
import { useCallback, useMemo } from "react";
import ScrollableFlexContainer from "../../../../../shared/components/ScrollableFlexContainer";
import { FieldConfiguration, ReportField } from "../../../../../shared/reporting/api/biClient.types";
import MeasureFieldsContainer from "../../common/fields/format/MeasureFieldsContainer";
import { isDimension, isMeasure } from "../../utils/isMeasure";
import { useFieldsStateContext } from "../contexts/FieldsStateContext";
import DimensionFormattingContainer from "./DimensionFormattingContainer";
import TextFieldContainer from "./TextFieldsContainer";

const FormatContainer = () => {
  const { fieldsArea } = useFieldsStateContext();

  const measures = useMemo(() => fieldsArea.values.filter(isMeasure), [fieldsArea.values]);
  const dimensions = useMemo(() => fieldsArea.values.filter(isDimension), [fieldsArea.values]);

  const handleMeasureChange = useCallback(
    (measure: ReportField, changes: Partial<FieldConfiguration>) => {
      const field = fieldsArea.values.find((f) => f.config.guid === measure.config.guid);
      if (isMeasure(field)) {
        fieldsArea.updateMeasureFieldConfig(field, changes);
      }
    },
    [fieldsArea]
  );

  const handleDimensionChange = useCallback(
    (dimension: ReportField, changes: Partial<FieldConfiguration>) => {
      const field = fieldsArea.values.find((f) => f.meta.name === dimension.meta.name);
      if (isDimension(field)) {
        fieldsArea.updateDimensionFieldConfig(field, changes);
      }
    },
    [fieldsArea]
  );

  return (
    <ScrollableFlexContainer scrollContainerSx={{ px: "1.3rem" }}>
      <Stack gap={1}>
        <TextFieldContainer fields={dimensions} onUpdateConfig={handleDimensionChange} />
        <DimensionFormattingContainer dimensions={dimensions} onUpdateConfig={handleDimensionChange} />
        <MeasureFieldsContainer measures={measures} onChange={handleMeasureChange} />
      </Stack>
    </ScrollableFlexContainer>
  );
};

export default FormatContainer;
