import React from "react";
import { FieldConfiguration, ReportField } from "../../../../shared/reporting/api/biClient.types";
import PreviewActions from "../common/PreviewActions";
import { isValueFieldHasParameters } from "../common/utilities/valueFieldUtils";
import ViewContainer from "../common/ViewContainer";
import ViewerParameters from "../common/ViewerParameters";
import { isMeasure } from "../utils/isMeasure";
import { useFieldsStateContext } from "./contexts/FieldsStateContext";
import useGridStateViewer from "./hooks/useGridStateViewer";
import withExport from "./preview/hocs/withExport";
import PreviewComponent from "./preview/PreviewComponent";

export default function TabularViewer() {
  const state = useGridStateViewer();
  const { conditionsArea, fieldsArea } = useFieldsStateContext();

  const fields = React.useMemo(
    () => conditionsArea.values.filter((v) => v.config.parameter === true),
    [conditionsArea.values]
  );

  const measuresToShow = React.useMemo(() => {
    return fieldsArea.values.filter(isMeasure).filter(isValueFieldHasParameters);
  }, [fieldsArea.values]);

  const showParameters = React.useMemo(() => {
    return fields.length > 0 || measuresToShow.length > 0;
  }, [fields, measuresToShow]);

  const updateMeasure = React.useCallback(
    (field: ReportField, change: Partial<FieldConfiguration>) => {
      const tableField = fieldsArea.values.find((v) => v.config.guid === field.config.guid);
      if (tableField !== undefined) {
        fieldsArea.updateMeasureFieldConfig(tableField, change);
      }
    },
    [fieldsArea]
  );

  return (
    <ViewContainer
      parameters={
        showParameters ? (
          <ViewerParameters
            conditionsArea={conditionsArea}
            measuresWithConditions={measuresToShow}
            updateMeasure={updateMeasure}
          />
        ) : (
          <></>
        )
      }
      preview={<PreviewComponentWithExport state={state} />}
      actionPanel={<PreviewActions />}
    ></ViewContainer>
  );
}

const PreviewComponentWithExport = withExport(PreviewComponent);
