import { List } from "@mui/material";
import { DimensionDescriptor } from "../../../../../../shared/reporting/api/biClient.types";
import { DimensionsStructure } from "../../../../../api/biApi.types";
import { buildTree } from "./DimensionsTree.Render";

const DimensionsTree = (props: { fields: DimensionDescriptor[]; structure: DimensionsStructure }) => {
  const { fields, structure } = props;

  return <List sx={{ p: 0 }}>{buildTree(fields, structure)}</List>;
};

export default DimensionsTree;
