import { MeasureDateRange, ReportField } from "../../../../../../shared/reporting/api/biClient.types";
import { isMeasure } from "../../../utils/isMeasure";
import { getCaptionByDateRangeType } from "../../preview/Helper";
import FieldCaption from "../FieldCaption";
import FieldCaptionIconsSet from "./FieldCaptionIconsSet";

export function formatMeasureCaption(measure: ReportField | undefined, captionColor?: string) {
  if (measure?.config.dateRange === undefined || measure?.config.dateRange === null) return <></>;
  const customCaption = dateRangeToString(measure.config.dateRange);

  return (
    <FieldCaption
      caption={measure.config.customLabel || measure.meta.caption}
      subCaption={customCaption}
      captionColor={captionColor}
    >
      <FieldCaptionIconsSet config={measure.config} />
    </FieldCaption>
  );
}

export function dateRangeToString(range: MeasureDateRange | undefined | null): string {
  switch (range) {
    case MeasureDateRange.BeginningBalance: {
      return "Beginning Balance";
    }
    case MeasureDateRange.EndingBalance: {
      return "Ending Balance";
    }
    case MeasureDateRange.Mtd: {
      return "MTD";
    }
    case MeasureDateRange.Qtd: {
      return "QTD";
    }
    case MeasureDateRange.Ytd: {
      return "YTD";
    }
  }
  return "";
}

export const getMeasureCaption = (field: ReportField | undefined) => {
  if (!isMeasure(field)) return "";
  return (
    field?.config.customLabel ||
    getCaptionByDateRangeType(field.meta.caption, field.config.dateRange || MeasureDateRange.EndingBalance)
  );
};
