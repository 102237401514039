import { Box, ListItem, SxProps, Theme, Typography } from "@mui/material";
import { useCallback, useEffect, useMemo } from "react";
import { useDrag, useDrop } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";
import CloseIconButton from "../../../../../shared/components/CloseIconButton";
import { AreaItemType, GroupingField } from "../../../../../shared/reporting/api/biClient.types";
import { useFieldsStateContext } from "../contexts/FieldsStateContext";

interface Props {
  field: GroupingField;
  sx?: SxProps<Theme>;
  onRemoveItem?: (field: GroupingField) => void;
  onEndMoveItem?: (field: GroupingField) => void;
  onDragMove?: (field: GroupingField, replaceField: GroupingField) => void;
}

export function GroupFieldItem(props: Props) {
  const { field, sx, onRemoveItem, onEndMoveItem, onDragMove } = props;
  const { fieldsArea } = useFieldsStateContext();

  const dimension = useMemo(() => fieldsArea.values.find((f) => f.config.guid === field.name), [field, fieldsArea]);

  const [{ isDragging }, drag, dragPreview] = useDrag(
    () => ({
      type: AreaItemType.GROUPS,
      item: field,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      end: (item, monitor) => {
        if (monitor.didDrop()) {
          onEndMoveItem?.call(null, item);
        }
      },
    }),
    [field]
  );

  const [, drop] = useDrop(
    () => ({
      accept: AreaItemType.GROUPS,
      hover(item: GroupingField) {
        if (item.name !== field.name) {
          onDragMove?.call(null, item, field);
        }
      },
    }),
    [field]
  );

  const onRef = useCallback(
    (node: HTMLLIElement | null) => {
      drag(drop(node));
    },
    [drag, drop]
  );

  const handleRemoveItem = useCallback(() => {
    onRemoveItem?.call(null, field);
  }, [onRemoveItem, field]);

  useEffect(() => {
    dragPreview(getEmptyImage());
  }, [dragPreview]);

  return (
    <ListItem
      role="Handle"
      ref={onRef}
      sx={{
        dispaly: "flex",
        flexDirection: "row",
        gap: ".5rem",
        height: "32px",
        borderRadius: "2px",
        p: "5px 0",
        opacity: isDragging ? 0 : 1,
        backgroundColor: "#F9F9F9",
        ...sx,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flex: 1,
          flexDirection: "row",
          alignItems: "center",
          border: "1px solid rgba(33, 33, 33, 0.35)",
          borderRadius: "2px",
          backgroundColor: "#fff",
          p: "0 5px",
          "&:hover": {
            cursor: "grab",
          },
        }}
      >
        <Typography
          variant="caption"
          sx={{ display: "flex", flexGrow: 1 }}
          color={(theme) => theme.palette.text.primary}
        >
          {dimension?.config.customLabel || dimension?.meta.caption}
        </Typography>
        <CloseIconButton onClick={handleRemoveItem} iconProps={{ htmlColor: "rgba(33, 33, 33, 0.38)" }} />
      </Box>
    </ListItem>
  );
}

export default GroupFieldItem;
