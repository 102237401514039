import { Stack } from "@mui/material";
import React from "react";
import ScrollableFlexContainer from "../../../../../../shared/components/ScrollableFlexContainer";
import { DimensionField, FieldConfiguration } from "../../../../../../shared/reporting/api/biClient.types";
import MeasureFieldsContainer from "../../../common/fields/format/MeasureFieldsContainer";
import { BuilderContext } from "../../types";
import TextFieldContainer from "./TextFieldsContainer";

export default function FormatContainer() {
  const { rowsArea, columnsArea, valuesArea } = React.useContext(BuilderContext);

  const fields = React.useMemo(() => rowsArea.values.concat(columnsArea.values), [rowsArea.values, columnsArea.values]);

  const handleUpdateConfig = React.useCallback(
    (field: DimensionField, changes: Partial<FieldConfiguration>) => {
      if (columnsArea.values.indexOf(field) > -1) {
        columnsArea.updateItemConfig(field, changes);
      } else if (rowsArea.values.indexOf(field) > -1) {
        rowsArea.updateItemConfig(field, changes);
      }
    },
    [rowsArea, columnsArea]
  );

  return (
    <ScrollableFlexContainer scrollContainerSx={{ px: "1.3rem" }}>
      <Stack gap={1}>
        <TextFieldContainer fields={fields} onUpdateConfig={handleUpdateConfig} />
        <MeasureFieldsContainer measures={valuesArea.values} onChange={valuesArea.updateItemConfig} />
      </Stack>
    </ScrollableFlexContainer>
  );
}
