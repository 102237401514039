import { Typography } from "@mui/material";
import React from "react";
import { ItemDataType, MeasureField, MeasureUsage } from "../../../../../shared/reporting/api/biClient.types";
import SwitchField from "../../../common/SwitchField";
import { getMeasureCaption } from "../../pivot/table/measures/helper";
import { isMeasure } from "../../utils/isMeasure";
import { useFieldsStateContext } from "../contexts/FieldsStateContext";
import ExpandeGroup from "./ExpandeGroup";
import StyleItem from "./StyleItem";

export default function MeasureSettings() {
  const { fieldsArea } = useFieldsStateContext();

  const numericMeasures = React.useMemo(() => {
    return fieldsArea.values.filter(
      (f): f is MeasureField =>
        isMeasure(f) && f.meta.type === ItemDataType.Numeric && f.meta.usage !== MeasureUsage.TabularOnly
    );
  }, [fieldsArea.values]);

  if (numericMeasures.length === 0) {
    return <></>;
  }

  return (
    <ExpandeGroup title="Measure Columns">
      {numericMeasures.map((field, index) => (
        <StyleItem
          key={field.config.guid}
          showBorder={numericMeasures.length > 1 && index < numericMeasures.length - 1}
        >
          <Typography variant="subtitle2" fontWeight={500} color={(theme) => theme.palette.text.primary} pb={1}>
            {getMeasureCaption(field)}
          </Typography>
          <SwitchField
            label="Calculate Subtotals"
            checked={!field.config.hideAggregation}
            onChange={(hideAggregation) =>
              fieldsArea.updateMeasureFieldConfig(field, { hideAggregation: !hideAggregation })
            }
            typographyProps={{ color: "text.secondary" }}
          />
        </StyleItem>
      ))}
    </ExpandeGroup>
  );
}
