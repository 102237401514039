import {
  GridColDef,
  GridColumnGroup,
  GridPinnedRowsProp,
  GridRenderCellParams,
  GridValidRowModel,
  useGridApiRef,
} from "@mui/x-data-grid-premium";
import { useMemo, useRef } from "react";
import DataGrid from "../../../../../shared/components/grid/DataGrid";
import { isPinnedRowNode } from "../../../../../shared/components/grid/gridHelper";
import InlineLoader from "../../../../../shared/components/inlineLoader/InlineLoader";
import { DimensionField } from "../../../../../shared/reporting/api/biClient.types";
import { generateGuid } from "../../../../../shared/utilities/generateGuid";
import {
  PivotDataGrandTotals,
  PivotRowDimensionCell,
  PivotRowMeasureCell,
  PivotRowResponse,
} from "../../../../api/biApi.types";
import { createGrandTotalLinesCell, createGrandTotalMeasureCell, createLinesCell, createMeasureCell } from "./Helper";
import useMergedSx from "../../../../../shared/hooks/useMergedSx";
import { getBiDataGridStyles } from "../../../../utilities/Utilities";
import useAutosizeColumns from "../../../../hooks/useAutosizeColumns";

interface Props {
  rows: PivotRowResponse[];
  columns: GridColDef[];
  columnGroups: GridColumnGroup[];
  size: { width: number; height: number };
  loading: boolean;
  rowFields: DimensionField[];
  grandTotals?: PivotDataGrandTotals;
  showSummaries?: boolean;
  onValueCellClick: (cell: PivotRowMeasureCell | undefined) => void;
}
const PivotGrid = ({
  columnGroups,
  columns,
  loading,
  rowFields,
  rows,
  size,
  onValueCellClick,
  grandTotals,
  showSummaries,
}: Props) => {
  const apiRef = useGridApiRef();
  const rowFieldsRef = useRef(rowFields);
  rowFieldsRef.current = rowFields;

  const { autosizeOptions } = useAutosizeColumns(apiRef, [columns]);

  const gridColumns = useMemo(
    () =>
      columns.map((c) => {
        if (c.field === "lines") {
          c.renderCell = (params: GridRenderCellParams<GridValidRowModel, PivotRowDimensionCell>) => {
            if (showSummaries && isPinnedRowNode(params.rowNode)) {
              return createGrandTotalLinesCell();
            }
            return createLinesCell(params.value, rowFieldsRef.current);
          };
        } else {
          c.renderCell = (params: GridRenderCellParams<GridValidRowModel, PivotRowMeasureCell>) => {
            if (showSummaries && isPinnedRowNode(params.rowNode)) {
              return createGrandTotalMeasureCell(params?.value?.formattedValue);
            }
            return createMeasureCell(params.value, rowFieldsRef.current);
          };
        }
        return c;
      }),
    [columns, showSummaries]
  );

  const pinnedRows = useMemo((): GridPinnedRowsProp => {
    if (!grandTotals || Object.keys(grandTotals || {}).length === 0) {
      return {};
    }
    return { bottom: [grandTotals] };
  }, [grandTotals]);

  const sx = useMergedSx(getBiDataGridStyles, { maxWidth: size.width });

  return (
    <DataGrid<PivotRowResponse | PivotDataGrandTotals>
      apiRef={apiRef}
      columns={gridColumns}
      columnGroupingModel={columnGroups}
      rows={rows}
      getRowId={getRowId}
      pinnedRows={pinnedRows}
      sx={sx}
      containerSx={{ alignItems: "center" }}
      scrollContainerSx={{ width: size.width }}
      rowHeight={32}
      disableRowSelectionOnClick
      initialState={{ pinnedColumns: { left: ["lines"] } }}
      autosizeOptions={autosizeOptions}
      loading={loading}
      hideFooter
      showCellVerticalBorder
      showColumnVerticalBorder
      columnHeaderHeight={32}
      slots={{ loadingOverlay: InlineLoaderOverlay }}
      slotProps={{ columnHeaders: { style: { backgroundColor: "#f5f5f5" } } }}
      onCellClick={({ field, value }) => {
        if (field === "lines") {
          return;
        }
        onValueCellClick(value as PivotRowMeasureCell);
      }}
    />
  );
};

function getRowId(row: PivotRowResponse) {
  const lines = row["lines"] as PivotRowDimensionCell;
  if (lines === undefined) {
    return generateGuid();
  }

  return lines.rowId;
}

function InlineLoaderOverlay() {
  return <InlineLoader sx={{ bgcolor: "#ffffff82", zIndex: 1 }} overlay />;
}

export default PivotGrid;
