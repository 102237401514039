import AddIcon from "@mui/icons-material/Add";
import { Button, Grid, SxProps, Theme } from "@mui/material";
import { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { DimensionDescriptor } from "../../../../../../shared/reporting/api/biClient.types";
import cloneDeep from "../../../../../../shared/utilities/cloneDeep";
import { currentReportActions } from "../../../../../store/currentReportSlice";
import { ConditionField } from "../../../Types";
import { createEmptyConditionItem } from "../../utilities/createEmptyConditionItem";
import CustomMeasureDimensionSelection from "./CustomMeasureDimensionSelection";

interface Props {
  text: string;
  conditions: ConditionField[] | undefined;
  dimensions: DimensionDescriptor[];
  onAddField: (field: ConditionField) => void;
  sx?: SxProps<Theme>;
}
export default function AddCustomMeasureConditions({ text, conditions, dimensions, onAddField, sx }: Props) {
  const dispatch = useDispatch();
  const [addConditionAnchorEl, setAddConditionAnchorEl] = useState<HTMLElement | null>(null);
  const [showAddConditions, setShowAddConditions] = useState(false);

  const conditionSelection = useMemo(() => {
    if (conditions === undefined) return dimensions;
    return dimensions.filter((f) => !conditions.some((fs) => f.name === fs.meta.name));
  }, [conditions, dimensions]);

  if (conditionSelection.length === 0) return null;

  return (
    <Grid item sx={sx}>
      <Button
        ref={setAddConditionAnchorEl}
        sx={{
          lineHeight: "20px",
          letterSpacing: "0.14px",
          fontSize: "12px",
          textWrap: "nowrap",
          height: "30px",
        }}
        startIcon={<AddIcon color="action" />}
        onClick={() => setShowAddConditions(true)}
      >
        {text}
      </Button>
      {addConditionAnchorEl && showAddConditions && (
        <CustomMeasureDimensionSelection
          anchorEl={addConditionAnchorEl}
          dimensions={conditionSelection}
          onClose={(value) => {
            if (value !== undefined) {
              const field = createEmptyConditionItem(cloneDeep(value));
              onAddField(field);
              dispatch(currentReportActions.setAutoShowItemOptionsId(field.config.guid));
            }
            setShowAddConditions(false);
          }}
        />
      )}
    </Grid>
  );
}
