import { DimensionField, ItemDataType } from "../../../../shared/reporting/api/biClient.types";
import FieldCaption from "../pivot/table/FieldCaption";
import { getDateGroupingLabel } from "./captionsHelper";

export const formatDimensionFieldCaptions = (
  field: DimensionField,
  getSubCaptions?: (field: DimensionField) => string
) => {
  return (
    <FieldCaption caption={field.config.customLabel || field.meta.caption} subCaption={getSubCaptions?.(field) || ""} />
  );
};

export const getGroupingCaption = (field: DimensionField) => {
  if (field?.meta.type === ItemDataType.Date) {
    return getDateGroupingLabel(field.config.grouping);
  }
  return "";
};
