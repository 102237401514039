import { Typography } from "@mui/material";
import { useState } from "react";
import { MenuItemStyled } from "../../../../../../shared/components/MenuItemStyled";
import { MeasureField } from "../../../../../../shared/reporting/api/biClient.types";
import ChartOfAccounts from "../../chartOfAccounts/ChartOfAccounts";

interface Props {
  measure: MeasureField;
  onClose: () => void;
}

export default function ChartOfAccountsItem({ measure, onClose }: Props) {
  const [showCharOfAccounts, setShowCharOfAccounts] = useState(false);

  return (
    <>
      <MenuItemStyled onClick={() => setShowCharOfAccounts(true)} sx={{ px: 2, py: 1.25 }}>
        <Typography>Chart of Accounts...</Typography>
      </MenuItemStyled>
      {showCharOfAccounts && measure && (
        <ChartOfAccounts
          initialMeasure={measure.meta}
          onClose={() => {
            setShowCharOfAccounts(false);
            onClose();
          }}
        />
      )}
    </>
  );
}
