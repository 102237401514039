import { GridColDef, GridColumnOrderChangeParams, GridSortModel } from "@mui/x-data-grid-premium";
import {
  DimensionDescriptor,
  DrillDownColumn,
  ItemDataType,
} from "../../../../../../shared/reporting/api/biClient.types";
import { ColumnSorting } from "../../../../../api/biApi.types";
import { formatDimension } from "../../../../../formatters/NumberFormatter";
import { FieldWithOrder, FieldWithSorting } from "../../../../../hooks/FieldWithOrder";
import { sortOrderedFields } from "../../../../../hooks/sortOrderedFields";

export const PAGE_SIZE = 500;

export const rebuildColumnsSorting = (model: GridSortModel, allFields: DimensionDescriptor[]) => {
  const allFieldsCopy = [...allFields];
  const newSorting = model
    .map((sort, index) => {
      const field = allFieldsCopy.find((f) => f.name === sort.field);
      if (field) {
        const sortField = {
          field,
          order: index,
          sortAsc: sort.sort === "asc",
        } as FieldWithSorting;
        return sortField;
      }
      return undefined;
    })
    .filter((field): field is FieldWithSorting => !!field);
  return newSorting;
};

export const rebuildColumnsOrder = (params: GridColumnOrderChangeParams, fields: FieldWithOrder[]) => {
  const arr = [...fields];
  const item = arr.splice(params.oldIndex, 1)[0];

  if (!item) {
    return arr;
  }
  arr.splice(params.targetIndex, 0, item);

  return arr.map((f, index) => {
    return { ...f, order: index };
  });
};

export const createColumns = (fields: FieldWithOrder[]): GridColDef[] => {
  const arr = [...fields];
  return arr.sort(sortOrderedFields).map((f) => {
    return {
      field: f.field.name,
      headerName: f.field.caption,
      width: 150,
      align: f.field.type === ItemDataType.Numeric ? "right" : "left",
      aggregable: false,
      hideable: false,
      pinnable: false,
      groupable: false,
      filterable: false,
      valueFormatter: (value) => {
        return formatDimension(f.field, value);
      },
    };
  });
};

export const shouldSkipFetch = (newSkip: number, newTake: number, prevSkip: number, prevTake: number) => {
  const emptyRequest = newSkip === 0 && newTake === 0;
  const sameRequest = prevSkip === newSkip && prevTake === newTake;

  // Check if the new request is within the range of the previous request
  const withinPreviousRange = newSkip >= prevSkip && newSkip + newTake <= prevSkip + prevTake;

  return emptyRequest || sameRequest || withinPreviousRange;
};

export const getDrillDownColumns = (fields: FieldWithOrder[]): DrillDownColumn[] => {
  return fields.map((f) => {
    const col: DrillDownColumn = {
      dimensionName: f.field.name,
    };
    return col;
  });
};

export const getSorting = (fields: FieldWithSorting[]): ColumnSorting[] => {
  return fields.map((f) => {
    const col: ColumnSorting = {
      dimensionName: f.field.name,
      sortAsc: f.sortAsc,
      caption: f.field.caption,
    };
    return col;
  });
};
