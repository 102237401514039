import {
  AreaItemType,
  DimensionDescriptor,
  MeasureDescriptor,
  MeasureField,
  MetaDescriptorBase,
  MetaItemType,
  ReportField,
} from "../../../../../shared/reporting/api/biClient.types.ts";
import { AnyObject } from "../../../../../shared/types";
import { ConditionField } from "../../Types";

import { DraggableFieldType, DraggableMetaType, FieldWrapper } from "../fields/types/dropField.types.ts";

export function extractGuid(item: FieldWrapper<DraggableFieldType> | DraggableMetaType): string | undefined {
  if (isConditionField(item)) {
    return item.field.config.guid;
  }

  if (isTableField(item)) {
    return item.field.config.guid;
  }

  if (isValueField(item)) {
    return item.field.config.guid;
  }
  return undefined;
}

export function extractMeta(
  item: FieldWrapper<DraggableFieldType> | DraggableMetaType
): DimensionDescriptor | MeasureDescriptor | undefined {
  if (isTableField(item)) {
    return item.field.meta;
  }
  if (isValueField(item)) {
    return item.field.meta;
  }
  if (isGeneralField(item)) {
    return item.field.meta;
  }
  if (isMeta(item)) {
    return item;
  }
  return undefined;
}

export function extractAreaItemType(item: FieldWrapper<DraggableFieldType> | DraggableMetaType): string | undefined {
  if (isFieldWrapper(item)) {
    if (isTableField(item)) {
      return item.field.areaItemType;
    }
    if (isGeneralField(item)) {
      return item.field.areaItemType;
    }
  }
  if (isMeta(item)) {
    return item.itemType === MetaItemType.DIMENSION ? AreaItemType.DIMENSION : AreaItemType.MEASURE;
  }
  return undefined;
}

export function extractCaptionFromFieldWrapper(item?: FieldWrapper<DraggableFieldType>): string | undefined {
  if (item && isFieldWrapper(item)) {
    if (isConditionField(item)) {
      return item.field.config.customLabel || item.field.config.systemLabel || item.field.meta.caption;
    }
    if (isTableField(item)) {
      return item.field.config?.customLabel || item.field.meta.caption;
    }
    if (isValueField(item)) {
      return item.field.config.customLabel || item.field.meta.caption;
    }
    if (isGeneralField(item)) {
      return item.field.config.customLabel || item.field.meta.caption;
    }
  }
  return undefined;
}

export function isFieldWrapper(item: AnyObject): item is FieldWrapper<DraggableFieldType> {
  return (item as FieldWrapper<DraggableFieldType>)?.field !== undefined;
}

function isTableField(item: AnyObject): item is FieldWrapper<ReportField> {
  return (item as FieldWrapper<ReportField>)?.field?.type !== undefined;
}

function isConditionField(item: AnyObject): item is FieldWrapper<ConditionField> {
  return (item as FieldWrapper<ConditionField>)?.field?.config?.guid !== undefined;
}

function isValueField(item: AnyObject): item is FieldWrapper<MeasureField> {
  const valueField = item as FieldWrapper<MeasureField>;
  return valueField?.field?.config?.guid !== undefined && valueField?.field?.meta !== undefined;
}

function isGeneralField(item: AnyObject): item is FieldWrapper<ReportField> {
  const generalField = item as FieldWrapper<ReportField>;
  return generalField?.field?.meta !== undefined && generalField?.field?.config !== undefined;
}

export function isMeta(item: AnyObject): item is MetaDescriptorBase {
  const meta = item as MetaDescriptorBase;
  return meta?.name !== undefined && meta?.itemType !== undefined && meta?.type !== undefined;
}

export function wrapItem<T extends DraggableFieldType>(item: T, index: number, type: string): FieldWrapper<T> {
  return { field: item, index, type };
}
