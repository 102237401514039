import { Stack, Typography } from "@mui/material";
import React from "react";
import { logError } from "../../../../shared/logging";
import { ReportField } from "../../../../shared/reporting/api/biClient.types";
import { useLocalization } from "../../../hooks/useLocalization";
import ReportWarningIcon from "../../../icons/ReportWarningIcon";
import { ConfigurationValidReturnType, InvalidConfigurationReason } from "../utils/isConfigurationValid";

interface Props {
  state: ConfigurationValidReturnType;
  measures: ReportField[];
}

export default function ValidationState({ state, measures }: Props) {
  const { validation: locale } = useLocalization();
  const errorTitle = React.useMemo(() => {
    if (state.valid || state.reason === undefined) return <></>;
    const measureField = measures.find((v) => v.config.guid === state.measure);
    if (measureField === undefined) return <></>;
    return (
      <Stack flexDirection="row" gap={0.5}>
        <Typography variant="subtitle1" fontWeight={400}>
          Measure
        </Typography>
        <Typography variant="subtitle1" color="error">
          {measureField.config.customLabel || measureField.meta.caption}
        </Typography>
        <Typography variant="subtitle1" fontWeight={400}>
          has configured incorrectly
        </Typography>
      </Stack>
    );
  }, [state, measures]);

  const errorText = React.useMemo(() => {
    if (state.valid || state.reason === undefined) return "";
    switch (state.reason) {
      case InvalidConfigurationReason.ConditionsFieldAndCustomConditions: {
        return locale.conditions_field_and_custom_conditions;
      }
      case InvalidConfigurationReason.FieldAndCustomConditions: {
        return locale.field_and_custom_conditions;
      }
      case InvalidConfigurationReason.MultipleCustomConditions: {
        return locale.multiple_custom_conditions;
      }
      case InvalidConfigurationReason.MultipleFields: {
        return locale.multiple_fields;
      }
      case InvalidConfigurationReason.NoDateFields: {
        return locale.no_date_fields;
      }
      case InvalidConfigurationReason.MandatoryFieldIsNotSelected: {
        return locale.mandatory_field_is_not_selected;
      }
      default: {
        logError("Unknown InvalidConfigurationReason", state.reason);
        return locale.configuration_is_not_valid;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  if (state.valid === true || state.reason === undefined) return <></>;
  return (
    <Stack alignItems="center" justifyContent="center" spacing={2} flex={1} mt={-10}>
      <ReportWarningIcon />
      <Stack spacing={1} alignItems="center" justifyContent="center">
        {errorTitle}
        <Typography textAlign="center">{errorText}</Typography>
      </Stack>
    </Stack>
  );
}
