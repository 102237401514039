import objectHash from "object-hash";
import { Ordering, ReportField } from "../../../../../shared/reporting/api/biClient.types";
import { TabularColumn } from "../../../../api/biApi.types";
import { getAllFilters } from "../../pivot/preview/utilities/getAllConditions";
import { ConditionField, SortField } from "../../Types";
import { getMeasures } from "../../utils/isMeasure";

export function getColumns(fields: ReportField[], columns: TabularColumn[]) {
  return fields.map((v) => {
    const column = columns.find((c) => c.guid === v.config.guid);
    let title = column?.title;
    if (title === undefined) {
      title = v.config.customLabel || v.meta.caption;
    }
    return {
      name: v.config.guid,
      title,
      failedToLoad: column?.failedToLoad || false,
    } as Column;
  });
}

export function getSorting(sorting: SortField[], fields: ReportField[]) {
  return sorting
    .map((v) => {
      const field = fields.find((value) => value.meta.name === v.meta.name);
      if (field === undefined) return undefined;
      return {
        columnName: field.config.guid,
        direction: v.config.ordering === Ordering.Ascending ? "asc" : "desc",
      } as Sorting;
    })
    .filter((r): r is Sorting => !!r);
}

export function getConditionsHash(conditions: ConditionField[], fields: ReportField[]) {
  const measures = getMeasures(fields);
  return objectHash(getAllFilters(conditions, measures));
}

type Sorting = {
  columnName: string;
  direction: "asc" | "desc";
};

export type Column = {
  name: string;
  title: string;
  failedToLoad: boolean;
};
