import { DimensionField, FieldConfiguration, Grouping } from "../../../../../../shared/reporting/api/biClient.types";
import GeneralFieldOptionPopup from "../columns/GeneralFieldOptionPopup";

interface Props {
  anchorEl: HTMLElement;
  field: DimensionField;
  saveChanges: (changes: Partial<FieldConfiguration>, causeClosing: boolean) => void;
  cancel: () => void;
  onRemove: () => void;
  onUpdateCustomLabel: (name: string | undefined) => void;
}

const RowFieldOptionPopup = (props: Props) => {
  return (
    <GeneralFieldOptionPopup
      {...props}
      supportedDateGroupings={[Grouping.ByYear, Grouping.ByQuarter, Grouping.ByMonth, undefined]}
    />
  );
};

export default RowFieldOptionPopup;
