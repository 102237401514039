import { Stack } from "@mui/material";
import React from "react";
import ScrollableFlexContainer from "../../../../../shared/components/ScrollableFlexContainer";
import { FieldConfiguration, ReportField } from "../../../../../shared/reporting/api/biClient.types";
import MeasureFieldsContainer from "../../common/fields/format/MeasureFieldsContainer";
import { useFieldsStateContext } from "../contexts/FieldsStateContext";
import TextFieldContainer from "./TextFieldsContainer";

export default function FormatContainer() {
  const { argumentsArea, valuesArea } = useFieldsStateContext();

  const handleMeasureChanged = React.useCallback(
    (measure: ReportField, changes: Partial<FieldConfiguration>) => {
      const field = valuesArea.values.find((f) => f.config.guid === measure.config.guid);
      if (field !== undefined) {
        valuesArea.updateItemConfig(field, changes);
      }
    },
    [valuesArea]
  );

  return (
    <ScrollableFlexContainer>
      <Stack gap={1} mx={"1.3rem"}>
        <TextFieldContainer fields={argumentsArea.values} onUpdateConfig={argumentsArea.updateItemConfig} />
        <MeasureFieldsContainer measures={valuesArea.values} onChange={handleMeasureChanged} />
      </Stack>
    </ScrollableFlexContainer>
  );
}
