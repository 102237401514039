import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { Grid, ListItemIcon, Stack, Tooltip, Typography } from "@mui/material";
import { useCallback } from "react";
import { MeasureDescriptor } from "../../../../../../shared/reporting/api/biClient.types";
import { getIcon } from "../FieldItem.helper";

interface Props {
  field: MeasureDescriptor;
  onShowOptions: (x: number, y: number, measure: MeasureDescriptor) => void;
}

export const MeasureFieldItem = ({ field, onShowOptions }: Props) => {
  const handleClick = useCallback(
    (evt: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      onShowOptions(evt.clientX, evt.clientY, field);
    },
    [field, onShowOptions]
  );

  return (
    <Grid container alignItems="center" onClick={handleClick}>
      <ListItemIcon
        sx={(theme) => ({
          minWidth: "20px",
          justifyContent: "center",
          color: field.custom ? theme.palette.info.main : theme.palette.secondary.light,
        })}
      >
        {getIcon(field)}
      </ListItemIcon>
      <Typography
        variant="caption"
        sx={{ flexGrow: 1, pointerEvents: "none", pl: 1 }}
        color={(theme) => theme.palette.text.primary}
      >
        {field.caption}
      </Typography>
      <Tooltip
        placement="right"
        title={
          <Stack>
            <Typography variant="caption">Drag to Move</Typography>
            <Typography variant="caption">Click to Open Menu</Typography>
          </Stack>
        }
      >
        <ListItemIcon sx={{ minWidth: "20px" }}>
          <DragIndicatorIcon color="disabled" />
        </ListItemIcon>
      </Tooltip>
    </Grid>
  );
};

export default MeasureFieldItem;
