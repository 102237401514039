import { Switch, Typography } from "@mui/material";
import { MenuItemStyled } from "../../../../../shared/components/MenuItemStyled";
import { BiAggregation, DimensionField } from "../../../../../shared/reporting/api/biClient.types";

interface Props {
  field: DimensionField;
  onChange: (value: boolean) => void;
}

export default function AggregateStringComponent({ field, onChange }: Props) {
  const value = field.config.aggregation?.aggregationType === BiAggregation.StringAgg;
  return (
    <MenuItemStyled sx={{ display: "flex", flex: 1, flexDirection: "row", gap: 1 }} onClick={() => onChange(!value)}>
      <Typography sx={{ display: "flex", flex: 1 }}>Combine Multiple Values in One Cell</Typography>
      <Switch checked={value} />
    </MenuItemStyled>
  );
}
