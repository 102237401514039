import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { ListItemIcon, Menu, Typography } from "@mui/material";
import { useCallback } from "react";
import AuthorizedBox from "../../../../../../shared/components/AuthorizedBox";
import { MenuItemStyled } from "../../../../../../shared/components/MenuItemStyled";
import EditReportIcon from "../../../../../../shared/icons/EditReportIcon";
import { MeasureDescriptor } from "../../../../../../shared/reporting/api/biClient.types";

interface Props {
  x: number;
  y: number;
  measure: MeasureDescriptor;
  onExplain: (measure: MeasureDescriptor) => void;
  onChartOfAccounts: (measure: MeasureDescriptor) => void;
  onEdit: (measure: MeasureDescriptor) => void;
  onDelete: (measure: MeasureDescriptor) => void;
  onClose: () => void;
}

const MeasureMenu = ({ x, y, measure, onExplain, onChartOfAccounts, onEdit, onDelete, onClose }: Props) => {
  const handleExplain = useCallback(() => {
    onExplain(measure);
  }, [measure, onExplain]);

  const handleChartOfAccounts = useCallback(() => {
    onChartOfAccounts(measure);
  }, [measure, onChartOfAccounts]);

  const handleEdit = useCallback(() => {
    onEdit(measure);
  }, [measure, onEdit]);

  const handleDelete = useCallback(() => {
    onDelete(measure);
  }, [measure, onDelete]);

  return (
    <Menu
      open
      anchorReference="anchorPosition"
      anchorPosition={{ top: y + 15, left: x }}
      onClose={onClose}
      slotProps={{ paper: { sx: { width: 200 } } }}
    >
      <MenuItemStyled selected={false} onClick={handleExplain}>
        <ListItemIcon>
          <InfoOutlinedIcon sx={(theme) => ({ color: theme.palette.secondary.light })} />
        </ListItemIcon>
        <Typography>Explain...</Typography>
      </MenuItemStyled>
      {measure.capabilities.chartOfAccountsAllowed && (
        <MenuItemStyled onClick={handleChartOfAccounts}>
          <ListItemIcon>
            <AccountTreeOutlinedIcon sx={(theme) => ({ color: theme.palette.secondary.light })} />
          </ListItemIcon>
          <Typography>Chart of Accounts...</Typography>
        </MenuItemStyled>
      )}
      <AuthorizedBox permissions={["ManageCustomMeasures"]}>
        {measure.custom && (
          <MenuItemStyled onClick={handleEdit}>
            <ListItemIcon>
              <EditReportIcon sx={(theme) => ({ color: theme.palette.secondary.light })} />
            </ListItemIcon>
            <Typography>Edit</Typography>
          </MenuItemStyled>
        )}
        {measure.custom && (
          <MenuItemStyled onClick={handleDelete}>
            <ListItemIcon>
              <DeleteOutlineIcon sx={(theme) => ({ color: theme.palette.secondary.light })} />
            </ListItemIcon>
            <Typography>Delete</Typography>
          </MenuItemStyled>
        )}
      </AuthorizedBox>
    </Menu>
  );
};

export default MeasureMenu;
