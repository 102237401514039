import { Box, Chip, Divider, Grid, Theme, Typography } from "@mui/material";
import { useCallback, useMemo } from "react";
import { ConditionEqualityType, ItemDataType } from "../../../../../../shared/reporting/api/biClient.types";
import { ConditionField } from "../../../Types";

const howManyItemsToShow = 7;

interface Props {
  field: ConditionField;
  onShowOptions: () => void;
}

export const AreaFieldItemDetailed = (props: Props) => {
  const { field, onShowOptions } = props;

  const valuesToRender = useMemo(() => {
    return field.config.filter?.values.slice(0, howManyItemsToShow) || [];
  }, [field.config.filter?.values]);

  const notShownValuesCount = useMemo(() => {
    const count = field.config.filter?.values.length || 0;
    if (count > howManyItemsToShow) {
      return count - howManyItemsToShow;
    }
    return 0;
  }, [field.config.filter?.values]);

  const getColor = useCallback((field: ConditionField, theme: Theme) => {
    if (field.invalid) {
      return theme.palette.text.secondary;
    }
    if (field.config.filter !== undefined && field.config.filter.equalityType === ConditionEqualityType.NotEqual) {
      return "#FF6D38";
    }
    return theme.palette.primary.main;
  }, []);

  if (field.meta.type === ItemDataType.Date || !field.config.filter || field.config.filter.values.length <= 1) {
    return <></>;
  }

  return (
    <Grid container direction={"column"} gap={1} pb={1} position="relative" sx={{ cursor: "default", px: "5px" }}>
      <Divider sx={{ borderStyle: "dashed" }} />
      <Grid
        container
        sx={{ overflow: "auto", flexDirection: "column", maxHeight: "150px", flexWrap: "nowrap", gap: 1 }}
      >
        {valuesToRender.map((value) => {
          return (
            <Typography key={value} variant="caption" color={(theme) => getColor(field, theme)}>
              {value}
            </Typography>
          );
        })}
      </Grid>
      {notShownValuesCount > 0 && (
        <Box
          sx={{
            position: "absolute",
            bottom: 0,
            width: "100%",
            display: "flex",
            flex: 1,
            justifyContent: "end",
            pr: 1.5,
            pb: 0.8,
          }}
        >
          <Chip
            label={
              <Typography variant="caption" color="white" fontWeight={500}>{`+${notShownValuesCount} more`}</Typography>
            }
            sx={{
              height: "auto",
              backgroundColor: "#6473817d",
              ".MuiChip-label": {
                px: 0.8,
              },
              "&:hover": {
                backgroundColor: "secondary.main",
              },
            }}
            onClick={onShowOptions}
          ></Chip>
        </Box>
      )}
    </Grid>
  );
};
