import { ReportField } from "../../../../../../shared/reporting/api/biClient.types";
import { isMeasure } from "../../../utils/isMeasure";
import SwitchMenuItem from "./SwitchMenuItem";

interface Props {
  measure: ReportField;
  saveAllowCustomConditions: (useCustomConditions: boolean) => void;
}

export default function CustomConditionsMenuItem(props: Props) {
  const { measure, saveAllowCustomConditions } = props;

  if (isMeasure(measure) && !measure.meta.capabilities.customConditionsAllowed) return <></>;

  return (
    <SwitchMenuItem
      caption="Custom Conditions"
      value={!!measure.config.useCustomConditions}
      onClick={() => saveAllowCustomConditions(!measure.config.useCustomConditions)}
    />
  );
}
