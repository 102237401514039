import React from "react";
import { useDispatch } from "react-redux";
import { useNotificationContext } from "../../../../../shared/contexts/NotificationContext";
import { ItemDataType } from "../../../../../shared/reporting/api/biClient.types";
import biClient from "../../../../api/biApi";
import { useLocalization } from "../../../../hooks/useLocalization";
import { metaDataActions } from "../../../../store/metaDataSlice";
import { unique } from "../../pivot/preview/utilities/unique";
import { ConditionField } from "../../Types";

export default function useDeferredDictionaryLoading(area: {
  values: ConditionField[];
  updateItem: (field: ConditionField, changes: Partial<ConditionField>) => void;
}) {
  const { metadata: locale } = useLocalization();
  const dispatch = useDispatch();
  const { sendNotificationError } = useNotificationContext();

  const dimensionsToLoadDictionaries = React.useMemo(() => {
    return area.values
      .filter((value) => value.meta)
      .filter(
        (value) =>
          value.meta.type === ItemDataType.General &&
          value.dictionary?.loaded !== true &&
          value.dictionary?.loading !== true &&
          value.dictionary?.failedToLoad !== true &&
          value.meta.dictionary.length === 0
      )
      .map((v) => v.meta.name)
      .filter(unique);
  }, [area.values]);

  const changeLoadingState = React.useCallback(
    (names: string[], loading: boolean, loaded: boolean, failedToLoad = false) => {
      names.forEach((name) => {
        const fields = area.values.filter((v) => v.meta.name === name);
        if (fields !== undefined) {
          fields.forEach((field) => {
            area.updateItem(field, { dictionary: { loading, loaded, failedToLoad } });
          });
        }
      });
    },
    [area]
  );

  React.useEffect(() => {
    const cancelled = false;
    if (dimensionsToLoadDictionaries.length > 0) {
      changeLoadingState(dimensionsToLoadDictionaries, true, false);
      biClient
        .loadDimensionDictionaries(...dimensionsToLoadDictionaries)
        .then((response) => {
          if (cancelled) return;
          if (response.success) {
            const result = response.data;
            changeLoadingState(dimensionsToLoadDictionaries, false, true);
            dispatch(metaDataActions.updateDictionaries(result));
          } else {
            sendNotificationError(locale.failed_to_load_dimension);
            changeLoadingState(dimensionsToLoadDictionaries, false, false, true);
          }
        })
        .catch(() => {
          if (cancelled) return;
          changeLoadingState(dimensionsToLoadDictionaries, false, false, true);
          sendNotificationError(locale.failed_to_load_dimension);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dimensionsToLoadDictionaries]);
}
