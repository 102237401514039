import { Grid, Tab, Tabs } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { MeasureDescriptor, MeasureUnitTable } from "../../../../../shared/reporting/api/biClient.types";
import { checkUnitTable } from "../../../../../shared/reporting/utils/checkUnitTable";
import { ChartOfAccounts } from "../../../../api/biApi.types";
import { PersistedTabPanel } from "../../../common/PersistedTabPanel";
import LedgerAccountsList from "./LedgerAccountsList";
import { useChartOfAccountsContext } from "./contexts/ChartOfAccountsContext";

interface LedgerAccountsListProps {
  chartOfAccounts: ChartOfAccounts | undefined;
  measure: MeasureDescriptor;
}
export default function LedgerAccountsContainer({ chartOfAccounts, measure }: LedgerAccountsListProps) {
  const { selectedAccounts, scrollTargetAccount } = useChartOfAccountsContext();

  const hasGl = useMemo(() => {
    return Object.values(measure.units).some((unit) => checkUnitTable(unit.table, MeasureUnitTable.Gl));
  }, [measure]);

  const hasMemo = useMemo(() => {
    return Object.values(measure.units).some((unit) => checkUnitTable(unit.table, MeasureUnitTable.Memo));
  }, [measure]);

  const [selectedTab, setSelectedTab] = useState<MeasureUnitTable>(hasGl ? MeasureUnitTable.Gl : MeasureUnitTable.Memo);

  useEffect(() => {
    if (selectedAccounts.length > 0) {
      const isGl = chartOfAccounts?.gl.some((account) => selectedAccounts.includes(account.accountNo));
      if (isGl) {
        setSelectedTab(MeasureUnitTable.Gl);
        return;
      }
      const isMemo = chartOfAccounts?.memo.some((account) => selectedAccounts.includes(account.accountNo));
      if (isMemo) {
        setSelectedTab(MeasureUnitTable.Memo);
      }
    }
  }, [chartOfAccounts?.gl, chartOfAccounts?.memo, selectedAccounts]);

  useEffect(() => {
    if (scrollTargetAccount?.accountNo) {
      const isGl = chartOfAccounts?.gl.some((account) => scrollTargetAccount.accountNo === account.accountNo);
      if (isGl) {
        setSelectedTab(MeasureUnitTable.Gl);
        return;
      }
      const isMemo = chartOfAccounts?.memo.some((account) => scrollTargetAccount.accountNo === account.accountNo);
      if (isMemo) {
        setSelectedTab(MeasureUnitTable.Memo);
      }
    }
  }, [chartOfAccounts?.gl, chartOfAccounts?.memo, scrollTargetAccount?.accountNo]);

  return (
    <Grid container sx={{ py: 2.5, pr: 3, pl: 1.5, gap: 2, flexDirection: "column" }}>
      <Tabs value={selectedTab} sx={{ pl: 2.5 }} onChange={(_, value) => setSelectedTab(value)}>
        {hasGl && <Tab key={MeasureUnitTable.Gl} label="General Ledger Accounts" value={MeasureUnitTable.Gl} />}
        {hasMemo && <Tab key={MeasureUnitTable.Memo} label="Memo Ledger Accounts" value={MeasureUnitTable.Memo} />}
      </Tabs>
      <Grid container sx={{ flex: 1 }}>
        <PersistedTabPanel key={MeasureUnitTable.Gl} value={MeasureUnitTable.Gl} selectedTab={selectedTab}>
          <LedgerAccountsList chartOfAccounts={chartOfAccounts} ledger={MeasureUnitTable.Gl} />
        </PersistedTabPanel>
        <PersistedTabPanel key={MeasureUnitTable.Memo} value={MeasureUnitTable.Memo} selectedTab={selectedTab}>
          <LedgerAccountsList chartOfAccounts={chartOfAccounts} ledger={MeasureUnitTable.Memo} />
        </PersistedTabPanel>
      </Grid>
    </Grid>
  );
}
