import { Typography } from "@mui/material";
import { useMemo } from "react";
import { FieldItemProps } from "../types/areaFiledItem.types";
import { getDefaultCaption } from "./AreaFieldsHelper";

const FieldItemCaptions = <T,>({
  field,
  formatCaption,
  disableEqualitySelection,
}: Pick<FieldItemProps<T>, "field" | "formatCaption" | "disableEqualitySelection">) => {
  const defaultCaption = useMemo(() => getDefaultCaption(field), [field]);
  const customCaption = formatCaption && formatCaption(field, undefined, disableEqualitySelection);

  if (customCaption) {
    return customCaption;
  }

  return (
    <Typography variant="caption" sx={{ flexGrow: 1 }} color={(theme) => theme.palette.text.primary}>
      {defaultCaption}
    </Typography>
  );
};

export default FieldItemCaptions;
