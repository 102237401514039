import { Box } from "@mui/material";
import DataSource from "devextreme/data/data_source";
import { createRef, useMemo } from "react";
import { useSelector } from "react-redux";
import { DimensionField, ReportField, ReportType } from "../../../../shared/reporting/api/biClient.types";
import { PivotRowResponse } from "../../../api/biApi.types";
import useResizeObserver from "../../../hooks/resizeObserver";
import { selectPreviewMode } from "../../../store/currentReportSlice";
import { PreviewDefaultSize } from "../common/constants/const";
import Area from "./Chart.Area";
import Bar from "./Chart.Bar";
import Doughnut from "./Chart.Doughnut";
import Line from "./Chart.Line";
import Pie from "./Chart.Pie";
import TreeMap from "./Chart.TreeMap";
import { ExportChartFormats } from "./ChartProps";

interface Props {
  type: ReportType;
  data: PivotRowResponse[];
  dataSource: DataSource;
  args: DimensionField[];
  values: ReportField[];
  exportFunc: (callback?: (fileName: string, format: ExportChartFormats) => void) => void;
}

export default function Chart(props: Props) {
  const previewMode = useSelector(selectPreviewMode);

  const maxHeight = useMemo(() => (previewMode === "maximize" ? undefined : 600), [previewMode]);
  const maxWidth = useMemo(() => (previewMode === "maximize" ? undefined : PreviewDefaultSize), [previewMode]);

  const divRef = createRef<HTMLDivElement>();
  const size = useResizeObserver(divRef, maxHeight, maxWidth);

  const renderChart = () => {
    if (props.type === ReportType.PieChart) {
      return <Pie {...props} size={size} />;
    } else if (props.type === ReportType.BarChart) {
      return <Bar {...props} size={size} />;
    } else if (props.type === ReportType.LineChart) {
      return <Line {...props} size={size} />;
    } else if (props.type === ReportType.AreaChart) {
      return <Area {...props} size={size} />;
    } else if (props.type === ReportType.DoughnutChart) {
      return <Doughnut {...props} size={size} />;
    } else if (props.type === ReportType.TreeMapChart) {
      return <TreeMap {...props} size={size} />;
    }
    return <>Chart Type not supported yet</>;
  };
  return (
    <Box
      component="div"
      ref={divRef}
      sx={{ display: "flex", flex: 1, justifyContent: "center", alignItems: "center", position: "relative" }}
    >
      <Box
        sx={{
          display: "flex",
          flex: 1,
          position: "absolute",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {renderChart()}
      </Box>
    </Box>
  );
}
