import { Stack } from "@mui/material";
import { FieldConfiguration, ItemDataType, ReportField } from "../../../../../../shared/reporting/api/biClient.types";
import FormatSettings from "./FormatSettings";

interface Props {
  measures: ReportField[];
  onChange: (measure: ReportField, changes: Partial<FieldConfiguration>) => void;
}

const MeasureFieldsContainer = ({ measures, onChange }: Props) => {
  return (
    <Stack gap={1}>
      {measures
        .filter((m) => m.meta.type === ItemDataType.Numeric)
        .map((value) => (
          <FormatSettings
            key={value.config.guid}
            label={value.config.customLabel || value.meta.caption}
            format={value.config.format}
            onUpdateFormatter={(formatter) => onChange(value, { format: formatter })}
          />
        ))}
    </Stack>
  );
};

export default MeasureFieldsContainer;
