import { Box, Typography } from "@mui/material";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import {
  AreaItemType,
  DimensionField,
  FieldConfiguration,
  ItemDataType,
  ReportField,
} from "../../../../../../shared/reporting/api/biClient.types";
import DropFieldContainer from "../../../common/fields/DropFieldContainer";
import { DraggableFieldType, DraggableMetaType, FieldWrapper } from "../../../common/fields/types/dropField.types";
import { createColumnField } from "../../../common/utilities/createFields";
import { extractMeta } from "../../../common/utilities/dropFieldContainerHelper";
import { ShowFieldOptionsSettings } from "../../../Types";
import { formatDimensionFieldCaptions, getGroupingCaption } from "../../../utils/formatDimensionFieldCaptions";
import { BuilderContext } from "../../types";
import ColumnFieldOptionPopup from "./ColumnFieldOptionPopup";

export const MaxColsNumber = 5;

export const ColumnsDropFieldsContainer = () => {
  const { columnsArea, rowsArea } = useContext(BuilderContext);

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [field, setField] = useState<DimensionField>();

  const columnsRef = useRef<ReportField[]>([]);
  columnsRef.current = columnsArea.values;

  const rowsRef = useRef<ReportField[]>([]);
  rowsRef.current = rowsArea.values;

  useEffect(() => {
    const column = columnsArea.values.find((v) => v.meta.name === field?.meta.name);
    setField(column);
  }, [columnsArea.values, field]);

  const showOptions = useCallback((settings: ShowFieldOptionsSettings<DimensionField>) => {
    setField(settings.field);
    setAnchorEl(settings.ref);
  }, []);

  const canDropItem = useCallback((item: FieldWrapper<ReportField> | DraggableMetaType) => {
    const meta = extractMeta(item);
    if (!meta) return false;
    if (columnsRef.current.length >= MaxColsNumber) {
      return false;
    }
    if (item.type === AreaItemType.ROWS && !columnsRef.current.some((v) => v.meta.name === meta.name)) {
      return true;
    }
    if (meta.type === ItemDataType.Date) {
      const anyDateFieldAdded =
        !!columnsRef.current?.find((f) => f.meta.type === ItemDataType.Date) ||
        !!rowsRef.current?.find((f) => f.meta.type === ItemDataType.Date);

      return anyDateFieldAdded === false;
    }

    return (
      !rowsRef.current.some((v) => v.meta.name === meta.name) &&
      !columnsRef.current.some((v) => v.meta.name === meta.name)
    );
  }, []);

  const saveChanges = useCallback(
    (changes: Partial<FieldConfiguration>) => {
      if (field === undefined) return;
      columnsArea.updateItemConfig(field, changes);
    },
    [columnsArea, field]
  );

  const handleSaveChanges = useCallback(
    (changes: Partial<FieldConfiguration>, causeClosing: boolean) => {
      saveChanges(changes);
      if (causeClosing) {
        setAnchorEl(null);
      }
    },
    [saveChanges]
  );

  const handleFormatCaptions = useCallback((field: DimensionField) => {
    return formatDimensionFieldCaptions(field, getGroupingCaption);
  }, []);

  const handleColumnFieldCustomLabelChange = (name: string | undefined) => {
    if (field) {
      columnsArea.updateItemConfig(field, { customLabel: name });
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: ".5rem" }}>
      <Typography variant="subtitle2" color={(theme) => theme.palette.text.primary}>
        Columns
      </Typography>
      <DropFieldContainer
        areaFieldType={AreaItemType.COLUMNS}
        acceptedDropTypes={[AreaItemType.DIMENSION, AreaItemType.ROWS, AreaItemType.CONDITIONS]}
        fields={columnsArea.values}
        getKeyValue={getKeyValue}
        canDropItem={canDropItem}
        formatCaption={handleFormatCaptions}
        onAddItem={columnsArea.addItem}
        onRemoveItem={columnsArea.removeItem}
        onMoveItem={columnsArea.moveItem}
        showOptions={showOptions}
        createListItem={createColumnField}
      />
      {anchorEl && field && (
        <ColumnFieldOptionPopup
          anchorEl={anchorEl}
          field={field}
          saveChanges={handleSaveChanges}
          cancel={() => setAnchorEl(null)}
          onRemove={() => {
            setAnchorEl(null);
            columnsArea.removeItem(field);
          }}
          onUpdateCustomLabel={handleColumnFieldCustomLabelChange}
        />
      )}
    </Box>
  );
};

export default ColumnsDropFieldsContainer;

function getKeyValue(field: FieldWrapper<DraggableFieldType> | DraggableMetaType) {
  return extractMeta(field)?.name ?? "";
}
