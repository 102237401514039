import {
  FieldConfiguration,
  GroupingField,
  Ordering,
  ReportField,
  SortConfiguration,
} from "../../../../../shared/reporting/api/biClient.types";
import cloneDeep from "../../../../../shared/utilities/cloneDeep";
import { SortField } from "../../Types";
import { isDimension } from "../../utils/isMeasure";

export const setGroupingFlag = (fields: FieldConfiguration[], groups: GroupingField[], sorts: SortConfiguration[]) => {
  const groupIds = new Set(groups.map((g) => g.name));
  const copy = cloneDeep(sorts);

  copy.forEach((sorting) => {
    const field = fields.find((f) => f.name === sorting.name);
    if (field && groupIds.has(field.guid)) {
      sorting.isGroupField = true;
    }
  });

  return copy;
};

export const addMissingGroupSorts = (
  fields: FieldConfiguration[],
  groups: GroupingField[],
  sorts: SortConfiguration[]
) => {
  const groupIds = new Set(groups.map((g) => g.name));
  const sortMetaNames = new Set(sorts.map((s) => s.name));

  const missingSorts = fields
    .filter((field) => groupIds.has(field.guid) && !sortMetaNames.has(field.name))
    .map(
      (field) =>
        ({
          name: field.name,
          isGroupField: true,
          ordering: Ordering.Ascending,
          caption: field.customLabel ?? field.name,
        }) as SortConfiguration
    );

  return [...cloneDeep(sorts), ...missingSorts];
};

export const sortSortingFields = (sorts: SortField[], groupMetaNames: string[]) => {
  const groupedFields = sorts.filter((field) => field.config.isGroupField);
  const regularFields = sorts.filter((field) => !field.config.isGroupField);

  groupedFields.sort(
    (a, b) => groupMetaNames.findIndex((g) => g === a.meta.name) - groupMetaNames.findIndex((g) => g === b.meta.name)
  );

  return [...groupedFields, ...regularFields];
};

export const getAvailableSortFields = (values: ReportField[], sortingAreaValues: SortField[]) => {
  return values.filter(isDimension).filter((f) => !sortingAreaValues.find((v) => v.meta.name === f.meta.name));
};
