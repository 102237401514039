import { FieldConfiguration, ItemDataType, ReportField } from "../../../../../../shared/reporting/api/biClient.types";
import SwitchMenuItem from "./SwitchMenuItem";

interface Props {
  measure: ReportField;
  saveChanges: (changes: Partial<FieldConfiguration>, causeClosing: boolean) => void;
}

export default function BlankZeroMenuItem(props: Props) {
  const { measure, saveChanges } = props;

  if (measure.meta.type !== ItemDataType.Numeric) return <></>;

  return (
    <SwitchMenuItem
      caption="Blank Zero"
      value={!!measure.config.blankZero}
      onClick={() => saveChanges({ blankZero: !measure.config.blankZero }, false)}
    />
  );
}
