import {
  AreaItemType,
  AxisSettings,
  ChartStyleSettings,
  ConditionConfiguration,
  DimensionDescriptor,
  DimensionField,
  FieldConfiguration,
  LegendSettings,
  Ordering,
  ReportField,
  ReportType,
  SeriesSettings,
  SortConfiguration,
  ValueAxisType,
} from "../../../../../shared/reporting/api/biClient.types";
import sortsSlice, {
  addSortingAction,
  moveSortingAction,
  removeSortingAction,
  removeSortingByMetaAction,
  setSortingAction,
  updateSortingAction,
  updateSortingConfigAction,
} from "../../common/hooks/fieldsState.sorts";
import useReducerSafe from "../../common/hooks/useReducerSafe";
import { ConditionField, SortField } from "../../Types";
import fieldsStateChartSlice, {
  addArgumentAction,
  addConditionAction,
  addMeasureAction,
  addSeriesAction,
  moveArgumentAction,
  moveConditionAction,
  moveMeasureAction,
  removeArgumentAction,
  removeConditionAction,
  removeMeasureAction,
  removeSeriesAction,
  setArgumentsAction,
  setConditionsAction,
  setMeasuresAction,
  setSetingsAction,
  updateArgumentAction,
  updateArgumentConfigAction,
  updateAxisArgumentSettingsAction,
  updateAxisValueSettingsAction,
  updateConditionAction,
  updateConditionConfigAction,
  updateLegendSettingsAction,
  updateMeasureAction,
  updateMeasureConfigAction,
  updateReportTypeSettingsAction,
  updateSeriesSettingsAction,
  updateSerieValueAxisSettingsAction,
  updateSettingsAction,
} from "./fieldsState.charts";

export default function useFieldsState() {
  const [state, dispatch] = useReducerSafe(fieldsStateChartSlice);
  const [sortingState, dispatchSorting] = useReducerSafe(sortsSlice);

  //CONDITIONS
  const setConditions = (fields: ConditionField[]) => dispatch(setConditionsAction(fields));

  const addCondition = (field: ConditionField, index: number) => {
    dispatch(addConditionAction({ field, index: index }));
  };

  const removeCondition = (field: ConditionField) => dispatch(removeConditionAction(field));

  const moveCondition = (field: ConditionField, newIndex: number) => dispatch(moveConditionAction({ field, newIndex }));

  const updateCondition = (field: ConditionField, changes: Partial<ConditionField>) =>
    dispatch(updateConditionAction({ field, changes }));

  const updateConditionConfig = (field: ConditionField, changes: Partial<ConditionConfiguration>) =>
    dispatch(updateConditionConfigAction({ field, changes }));

  //ARGUMENTS
  const setArguments = (fields: DimensionField[]) => dispatch(setArgumentsAction(fields));

  const addArgument = (field: DimensionField, index: number) => {
    dispatch(addArgumentAction({ field, index: index }));
  };

  const removeArgument = (field: DimensionField) => {
    removeSortingByMeta(field.meta);
    dispatch(removeArgumentAction(field));
  };

  const moveArgument = (field: DimensionField, newIndex: number) => dispatch(moveArgumentAction({ field, newIndex }));

  const updateArgument = (field: DimensionField, changes: Partial<DimensionField>) =>
    dispatch(updateArgumentAction({ field, changes }));

  const updateArgumentConfig = (field: DimensionField, changes: Partial<FieldConfiguration>) => {
    dispatch(updateArgumentConfigAction({ field, changes }));
    if (field.config.customLabel !== changes.customLabel) {
      const sortingField = sortingState.sorts.find((s) => s.meta.name === field.meta.name);
      if (sortingField) {
        updateSortingConfig(sortingField, { caption: changes.customLabel });
      }
    }
  };

  //MEASURES
  const setMeasures = (fields: ReportField[]) => {
    dispatch(setMeasuresAction(fields));
    fields.forEach((field) => addSerie(field.config.guid));
  };

  const addMeasure = (field: ReportField, index: number) => {
    dispatch(addSeriesAction(field.config.guid));
    dispatch(addMeasureAction({ field, index: index }));
  };

  const removeMeasure = (field: ReportField) => {
    dispatch(removeSeriesAction(field.config.guid));
    dispatch(removeMeasureAction(field));
  };

  const moveMeasure = (field: ReportField, newIndex: number) => dispatch(moveMeasureAction({ field, newIndex }));

  const updateMeasure = (field: ReportField, changes: Partial<ReportField>) =>
    dispatch(updateMeasureAction({ field, changes }));

  const updateMeasureConfig = (field: ReportField, changes: Partial<FieldConfiguration>) =>
    dispatch(updateMeasureConfigAction({ field, changes }));

  //SORTING
  const setSorting = (fields: SortField[]) => dispatchSorting(setSortingAction({ fields, groupMetaNames: [] }));

  const addSorting = (field: DimensionField) => {
    const conditionField: SortField = {
      meta: field.meta,
      config: {
        name: field.meta.name,
        ordering: Ordering.Ascending,
        caption: field.config.customLabel || field.meta.caption,
      },
      areaItemType: AreaItemType.SORTS,
    };
    dispatchSorting(addSortingAction(conditionField));
  };

  const removeSorting = (field: SortField) => dispatchSorting(removeSortingAction(field));

  const removeSortingByMeta = (field: DimensionDescriptor) => dispatchSorting(removeSortingByMetaAction(field));

  const moveSorting = (field: SortField, newIndex: number) => dispatchSorting(moveSortingAction({ field, newIndex }));

  const updateSorting = (field: SortField, changes: Partial<SortField>) =>
    dispatchSorting(updateSortingAction({ field, changes }));

  const updateSortingConfig = (field: SortField, changes: Partial<SortConfiguration>) =>
    dispatchSorting(updateSortingConfigAction({ field, changes }));

  //SETTINGS
  const setSettings = (settings: ChartStyleSettings) => dispatch(setSetingsAction(settings));
  const updateReportTypeSettings = (type: ReportType) => dispatch(updateReportTypeSettingsAction(type));
  const updateSettings = (changes: Partial<ChartStyleSettings>) => dispatch(updateSettingsAction(changes));
  const updateLegendSettings = (changes: Partial<LegendSettings>) => dispatch(updateLegendSettingsAction(changes));
  const updateArgumentAxisSettings = (changes: Partial<AxisSettings>) =>
    dispatch(updateAxisArgumentSettingsAction(changes));
  const updateValueAxisSettings = (name: string, changes: Partial<AxisSettings>) =>
    dispatch(updateAxisValueSettingsAction({ name, changes }));
  const addSerie = (name: string) => dispatch(addSeriesAction(name));
  const removeSerie = (name: string) => {
    dispatch(removeSeriesAction(name));
  };
  const updateSeriesSettings = (name: string, changes: Partial<SeriesSettings>) =>
    dispatch(updateSeriesSettingsAction({ name, changes }));
  const updateSerieValueAxis = (name: string, axis: ValueAxisType) =>
    dispatch(updateSerieValueAxisSettingsAction({ name, axis }));

  return {
    ...state,
    ...sortingState,

    setConditions,
    addCondition,
    updateCondition,
    updateConditionConfig,
    removeCondition,
    moveCondition,

    setArguments,
    addArgument,
    updateArgument,
    updateArgumentConfig,
    removeArgument,
    moveArgument,

    setMeasures,
    addMeasure,
    updateMeasure,
    updateMeasureConfig,
    removeMeasure,
    moveMeasure,

    setSorting,
    addSorting,
    removeSorting,
    removeSortingByMeta,
    moveSorting,
    updateSorting,
    updateSortingConfig,

    setSettings,
    updateSettings,
    updateReportTypeSettings,
    updateLegendSettings,
    updateArgumentAxisSettings,
    updateValueAxisSettings,
    addSerie,
    removeSerie,
    updateSeriesSettings,
    updateSerieValueAxis,
  };
}

export type FieldsStateReturnType = ReturnType<typeof useFieldsState>;
