import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { ConditionConfiguration, ReportField } from "../../../../../../shared/reporting/api/biClient.types";
import { RunPivotReportRequest } from "../../../../../api/biApi.types";
import { selectCurrentReport, selectSessionId } from "../../../../../store/currentReportSlice";
import { selectLogQueries } from "../../../../../store/devToolsSlice";
import { ConditionField } from "../../../Types";
import { BuilderContext } from "../../types";

export function useRequestConfigViewer() {
  const { conditionsArea, valuesArea } = useContext(BuilderContext);

  const report = useSelector(selectCurrentReport);
  const isQueryLoggingActive = useSelector(selectLogQueries);
  const sessionId = useSelector(selectSessionId);

  const requestConfig = React.useMemo(() => {
    const request: RunPivotReportRequest = {
      conditions: getConditions(conditionsArea.values, valuesArea.values),
      reportId: report?.reportId || "",
      sessionId,
      withDrilldown: true,
      useQueryLogging: isQueryLoggingActive === true,
    };
    return request;
  }, [conditionsArea.values, valuesArea.values, sessionId, report?.reportId, isQueryLoggingActive]);

  return { requestConfig };
}

function getConditions(allConditions: ConditionField[], values: ReportField[]) {
  const customConditions = values
    .filter((f) => f.config?.useCustomConditions && f.config?.customConditions)
    .map((f) => f.config?.customConditions)
    .flat()
    .filter((c): c is ConditionConfiguration => c !== undefined);

  const conditions = [...allConditions.map((c) => ({ ...c.config, invalid: c.invalid })), ...customConditions].filter(
    (c) => c.parameter === true
  );
  return conditions;
}
