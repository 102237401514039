import React, { PropsWithChildren, useContext } from "react";
import {
  DimensionField,
  FieldConfiguration,
  GroupingField,
  ReportField,
  TabularGeneralSettings,
} from "../../../../../shared/reporting/api/biClient.types";
import { defined } from "../../../../../shared/utilities/typeHelper";
import { ConditionFieldsArea, SortingFieldsArea } from "../../pivot/types";

export type FieldsArea = {
  get values(): ReportField[];
  addItem: (item: ReportField, index: number) => void;
  removeItem: (item: ReportField) => void;
  moveItem: (item: ReportField, newIndex: number) => void;
  updateDimensionField: (field: DimensionField, changes: Partial<DimensionField>) => void;
  updateDimensionFieldConfig: (field: DimensionField, changes: Partial<FieldConfiguration>) => void;
  updateMeasureField: (field: ReportField, changes: Partial<ReportField>) => void;
  updateMeasureFieldConfig: (field: ReportField, changes: Partial<FieldConfiguration>) => void;
  updateFieldsOrder: (guids: string[]) => void;
};

export type GroupingArea = {
  values: GroupingField[];
  setGrouping: (grouping: GroupingField[]) => void;
  addItem: (item: GroupingField) => void;
  removeItem: (group: GroupingField) => void;
  moveItem: (item: GroupingField, newIndex: number) => void;
  updateGroup: (group: GroupingField, changes: Partial<GroupingField>) => void;
};

export type SettingsArea = {
  settings: TabularGeneralSettings;
  update: (changes: Partial<TabularGeneralSettings>) => void;
};

export type FieldsStateContextType = {
  conditionsArea: ConditionFieldsArea;
  fieldsArea: FieldsArea;
  sortingArea: SortingFieldsArea;
  groupingArea: GroupingArea;
  settingsArea: SettingsArea;
};

const FieldsStateContext = React.createContext<FieldsStateContextType | undefined>(undefined);

export const FieldsStateContextProvider = (props: PropsWithChildren<FieldsStateContextType>) => (
  <FieldsStateContext.Provider value={{ ...props }}>{props.children}</FieldsStateContext.Provider>
);

export const useFieldsStateContext = () => {
  const context = useContext(FieldsStateContext);
  return defined(context);
};
