import { DimensionDictionary } from "../../../../../../shared/reporting/api/biClient.types";
import { ChartOfAccountsItem } from "../../../../../api/biApi.types";
import { ConditionField } from "../../../Types";
import { FormulaNode } from "../../customMeasure/utilities/fillNodes";

export function getFilterUniqueId(index: number, condition: ConditionField, parentId: string | undefined) {
  return `${parentId || ""}-${index}-${condition.meta.name}`;
}

export function getVariablePropertyItemUniqueId(index: number, fn: FormulaNode, parentId: string | undefined) {
  return `${parentId || ""}${index}-var-${fn.key}`;
}

export function getFilterFieldSubItemUniqueId(item: DimensionDictionary, parentId: string | undefined) {
  return `${parentId || ""}-${Object.values(item).join("-")}`;
}

export function getChartOfAccountUniqueId(index: number, item: ChartOfAccountsItem, parentId: string | undefined) {
  return `${parentId || ""}-${index}-${Object.values(item).join("-")}`;
}

export function getFunctionPropertyItemUniqueId(index: number, fn: FormulaNode) {
  return `${index}-fn-${fn.key}`;
}
