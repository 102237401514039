import { createContext } from "react";
import {
  ConditionConfiguration,
  DimensionField,
  FieldConfiguration,
  MetaDescriptorBase,
  MetaField,
  PivotGeneralSettings,
  ReportField,
  SortConfiguration,
} from "../../../../shared/reporting/api/biClient.types";
import { ConditionField, SortField } from "../Types";

export type FieldArea<T extends MetaField<MetaDescriptorBase>, TC> = {
  get values(): T[];
  removeItem: (item: T) => void;
  moveItem: (item: T, newIndex: number) => void;
  updateItem: (item: T, changes: Partial<T>) => void;
  updateItemConfig: (item: T, changes: Partial<TC>) => void;
};

export type ConditionFieldsArea = FieldArea<ConditionField, ConditionConfiguration> & {
  addItem: (item: ConditionField, index: number) => void;
};

export type RowFieldsArea = FieldArea<DimensionField, FieldConfiguration> & {
  addItem: (item: DimensionField, index: number) => void;
};

export type ColumnFieldsArea = FieldArea<DimensionField, FieldConfiguration> & {
  addItem: (item: DimensionField, index: number) => void;
};

export type ValueFieldsArea = FieldArea<ReportField, FieldConfiguration> & {
  addItem: (item: ReportField, index: number) => void;
};

export type SortingFieldsArea = FieldArea<SortField, SortConfiguration> & {
  addItem: (item: DimensionField, isGroupField: boolean, groupMetaNames: string[]) => void;
  setSorting: (fields: SortField[], groupMetaNames: string[]) => void;
};

export type GeneralSettingsArea = {
  settings: PivotGeneralSettings;
  update: (changes: Partial<PivotGeneralSettings>) => void;
};

export type BuilderContextType = {
  conditionsArea: ConditionFieldsArea;
  rowsArea: RowFieldsArea;
  columnsArea: ColumnFieldsArea;
  valuesArea: ValueFieldsArea;
  sortArea: SortingFieldsArea;
  settingsArea: GeneralSettingsArea;
};

export const BuilderContext = createContext<BuilderContextType>({} as BuilderContextType);
