import { MetaDescriptorBase, MetaField } from "../../../../../../shared/reporting/api/biClient.types";
import { AnyObject } from "../../../../../../shared/types";

export const DEFAULT_AREA_FIELD_ITEM_HEIGHT = 32;

export const getDefaultCaption = <T>(field: T) => {
  const item = field as unknown as MetaField<MetaDescriptorBase>;
  if (item.meta !== undefined) return item.meta.caption;
  const anyItem = field as AnyObject;
  return anyItem["caption"] as string;
};
