import { Box, Grid } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { FieldConfiguration, MeasureDateRange, ReportField } from "../../../../../shared/reporting/api/biClient.types";
import { selectDimensions } from "../../../../store/metaDataSlice";
import TypographyWithTooltip from "../../../common/TypographyWithTooltip";
import DateRanges from "../../pivot/table/measures/DateRanges";
import { dateRangeToString } from "../../pivot/table/measures/helper";
import { getDimensionFields, isMeasureConfigurationValid } from "../../utils/isConfigurationValid";
import { useFieldsStateContext } from "../contexts/FieldsStateContext";

interface Props {
  measure: ReportField;
  onClose: () => void;
  saveChanges: (changes: Partial<FieldConfiguration>, causeClosing: boolean) => void;
}

export default function PeriodMenuItem({ measure, onClose, saveChanges }: Props) {
  const updateDateRangeType = React.useCallback(
    (newDateRangeType: MeasureDateRange) => {
      if (measure.config.dateRange === newDateRangeType) {
        onClose();
      } else {
        saveChanges({ dateRange: newDateRangeType }, true);
      }
    },
    [measure, onClose, saveChanges]
  );

  return (
    <Grid container>
      <DateRanges
        dateRange={measure.config.dateRange ?? MeasureDateRange.EndingBalance}
        onChange={updateDateRangeType}
      />
      <ReferenceDateField measure={measure} />
    </Grid>
  );
}

function ReferenceDateField({ measure }: Pick<Props, "measure">) {
  const { conditionsArea, fieldsArea } = useFieldsStateContext();
  const dimensions = useSelector(selectDimensions);

  const measureValidity = React.useMemo(
    () =>
      isMeasureConfigurationValid(conditionsArea.values, getDimensionFields(fieldsArea.values), dimensions, measure),
    [conditionsArea.values, fieldsArea.values, dimensions, measure]
  );

  const isReferenceDateApplicable = React.useMemo(() => {
    return (
      (measureValidity.valid && measureValidity.referenceDateField !== undefined) ||
      measure.config.dateRange !== MeasureDateRange.NetChange
    );
  }, [measureValidity, measure]);

  if (!isReferenceDateApplicable) return <></>;

  return (
    <Box sx={{ mt: -1.2 }}>
      <TypographyWithTooltip
        tooltipText={`Date Dimension that is used for calculating ${dateRangeToString(
          measure.config.dateRange
        )} and the one is determined by the Date Dimension Fields order in the Conditions: first Date Dimension is considered as a Date field to use.`}
        label={`by ${measureValidity.referenceDateField?.caption}`}
        typographyProps={{ variant: "caption", color: "primary" }}
      />
    </Box>
  );
}
