import { Box, Stack, Typography } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  AreaItemType,
  DimensionField,
  FieldConfiguration,
  ItemDataType,
  MetaDescriptorBase,
  MetaItemType,
} from "../../../../../../shared/reporting/api/biClient.types";
import DropFieldContainer from "../../../common/fields/DropFieldContainer";
import { DraggableFieldType, DraggableMetaType, FieldWrapper } from "../../../common/fields/types/dropField.types";
import { createArgument } from "../../../common/utilities/createFields";
import { extractMeta } from "../../../common/utilities/dropFieldContainerHelper";
import GeneralFieldOptionPopup from "../../../pivot/table/columns/GeneralFieldOptionPopup";
import { ShowFieldOptionsSettings } from "../../../Types";
import { formatDimensionFieldCaptions, getGroupingCaption } from "../../../utils/formatDimensionFieldCaptions";
import { isDimension } from "../../../utils/isMeasure";
import { useFieldsStateContext } from "../../contexts/FieldsStateContext";

export const ArgumentDropFieldsContainer = () => {
  const { argumentsArea } = useFieldsStateContext();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [field, setField] = useState<DimensionField>();

  const argumentsRef = useRef<DimensionField[]>([]);
  argumentsRef.current = argumentsArea.values;
  useEffect(() => {
    const row = argumentsArea.values.find((v) => v.meta.name === field?.meta.name);
    setField(row);
  }, [field, argumentsArea.values]);
  const handleShowOptions = useCallback((settings: ShowFieldOptionsSettings<DimensionField>) => {
    setField(settings.field);
    setAnchorEl(settings.ref);
  }, []);

  const canDropArgumentItem = useCallback((item: unknown) => {
    const field = item as MetaDescriptorBase;
    if (argumentsRef.current.length >= 1) {
      return false;
    }
    if (field.type === ItemDataType.Date) {
      const anyDateFieldAdded = !!argumentsRef.current?.find((f) => f.meta.type === ItemDataType.Date);
      return anyDateFieldAdded === false;
    }
    return !argumentsRef.current.some((v) => v.meta.name === field.name);
  }, []);

  const onClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const saveChanges = useCallback(
    (changes: Partial<FieldConfiguration>) => {
      if (isDimension(field)) {
        argumentsArea.updateItemConfig(field, changes);
      }
    },
    [argumentsArea, field]
  );

  const handleSaveChanges = useCallback(
    (changes: Partial<FieldConfiguration>, causeClosing: boolean) => {
      saveChanges(changes);
      if (causeClosing) {
        onClose();
      }
    },
    [onClose, saveChanges]
  );

  const onRemove = useCallback(() => {
    if (isDimension(field)) {
      argumentsArea.removeItem(field);
    }
    setAnchorEl(null);
  }, [argumentsArea, field]);

  const handleFormatCaptions = useCallback((field: DimensionField) => {
    return formatDimensionFieldCaptions(field, getGroupingCaption);
  }, []);

  const handleArgumentFieldCustomLabelChange = (name: string | undefined) => {
    if (field) {
      argumentsArea.updateItemConfig(field, { customLabel: name });
    }
  };

  return (
    <Box
      sx={() => ({
        display: "flex",
        flexDirection: "column",
        gap: ".5rem",
      })}
    >
      <Stack direction="row" gap={0.5}>
        <Typography variant="subtitle2" color={(theme) => theme.palette.text.primary}>
          Arguments
        </Typography>
        <Typography variant="subtitle2" color={(theme) => theme.palette.text.disabled}>
          (Dimensions)
        </Typography>
      </Stack>
      <DropFieldContainer
        areaFieldType={AreaItemType.ARGUMENTS}
        acceptedDropTypes={[MetaItemType.DIMENSION, AreaItemType.CONDITIONS]}
        fields={argumentsArea.values}
        getKeyValue={getKeyValue}
        formatCaption={handleFormatCaptions}
        canDropItem={canDropArgumentItem}
        onAddItem={argumentsArea.addItem}
        onRemoveItem={argumentsArea.removeItem}
        onMoveItem={argumentsArea.moveItem}
        showOptions={handleShowOptions}
        createListItem={createArgument}
      />
      {anchorEl && field && (
        <GeneralFieldOptionPopup
          anchorEl={anchorEl}
          field={field}
          saveChanges={handleSaveChanges}
          cancel={onClose}
          onRemove={onRemove}
          onUpdateCustomLabel={handleArgumentFieldCustomLabelChange}
        />
      )}
    </Box>
  );
};

export default ArgumentDropFieldsContainer;

function getKeyValue(field: FieldWrapper<DraggableFieldType> | DraggableMetaType) {
  return extractMeta(field)?.name ?? "";
}
