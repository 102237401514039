import { List } from "@mui/material";
import objectHash from "object-hash";
import { MeasureDescriptor } from "../../../../../../shared/reporting/api/biClient.types";
import { MeasureGroup, MeasuresStructure } from "../../../../../api/biApi.types";
import FieldItemContainer from "../FieldItem";
import GroupListItem from "../GroupListItem";
import MeasureFieldItem from "./MeasureFieldItem";

const buildTreeGroup = (
  measures: MeasureDescriptor[],
  group: MeasureGroup,
  onShowOptions: (x: number, y: number, measure: MeasureDescriptor, group: string) => void
) => {
  const items: JSX.Element[] = [];
  const ids: JSX.Element[] = [];

  const showOptions = (x: number, y: number, measure: MeasureDescriptor) => {
    onShowOptions(x, y, measure, group.caption);
  };

  const measuresInGroup = measures.filter((m) => group.names.indexOf(m.name) > -1);
  if (measuresInGroup.length > 0) {
    ids.push(
      <List key={objectHash(group.names)} sx={{ p: 0 }}>
        {measuresInGroup.map((measure) => {
          return (
            <FieldItemContainer key={measure.name} field={measure}>
              <MeasureFieldItem field={measure} onShowOptions={showOptions} />
            </FieldItemContainer>
          );
        })}
      </List>
    );
  }
  if (ids.length > 0) {
    items.push(
      <GroupListItem key={group.caption} caption={group.caption}>
        {ids}
      </GroupListItem>
    );
  }
  return items;
};

export const buildTree = (
  measures: MeasureDescriptor[],
  structure: MeasuresStructure,
  onShowOptions: (x: number, y: number, measure: MeasureDescriptor, group: string) => void
) => {
  const items: JSX.Element[] = [];
  structure.groups.forEach((group) => {
    items.push(...buildTreeGroup(measures, group, onShowOptions));
  });
  return items;
};
