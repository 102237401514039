import { useCallback } from "react";
import {
  BiAggregation,
  DimensionField,
  FieldConfiguration,
  Grouping,
  ItemDataType,
} from "../../../../../shared/reporting/api/biClient.types";
import CustomLabel from "../../../common/customLabel/CustomLabel";
import FieldOptionDeleteButton from "../../../common/FieldOptionDeleteButton";
import FieldOptionsDivider from "../../../common/FieldOptionsDivider";
import FieldPopover from "../../common/fields/FieldPopover";
import { SuppressNoValueDimensionComponent } from "../../common/fields/SuppressNoValueComponent";
import { referenceDateFields } from "../utilities/referenceDateFields";
import AggregateStringComponent from "./AggregateStringComponent";
import DateGroupingOptionsList from "./DateGroupingOptionsList";

interface Props {
  anchorEl: HTMLElement;
  field: DimensionField;
  cancel: () => void;
  onRemove: () => void;
  onUpdateConfiguration: (changes: Partial<FieldConfiguration>, causeClosing: boolean) => void;
  onUpdateCustomLabel: (name: string | undefined) => void;
}
export default function GeneralFieldOptionPopup({
  anchorEl,
  field,
  cancel,
  onRemove,
  onUpdateConfiguration,
  onUpdateCustomLabel,
}: Props) {
  const showGroupings = referenceDateFields.includes(field.meta.name);

  const isTextField = field.meta.type === ItemDataType.General;

  const handleRemove = () => {
    onRemove();
    cancel();
  };

  const updateGrouping = useCallback(
    (newGrouping: Grouping | undefined) => {
      if (field.config.grouping !== newGrouping) {
        onUpdateConfiguration({ grouping: newGrouping }, true);
      }
    },
    [field.config.grouping, onUpdateConfiguration]
  );

  const updateSuppression = useCallback(
    (value: boolean) => {
      if (field.config.suppressEmptyValues !== value) {
        onUpdateConfiguration({ suppressEmptyValues: value }, false);
      }
    },
    [field.config.suppressEmptyValues, onUpdateConfiguration]
  );

  const updateAggregation = useCallback(
    (value: boolean) => {
      if (value) {
        onUpdateConfiguration({ aggregation: { aggregationType: BiAggregation.StringAgg } }, false);
      } else {
        onUpdateConfiguration({ aggregation: undefined }, false);
      }
    },
    [onUpdateConfiguration]
  );

  return (
    <FieldPopover open={!!anchorEl} anchorEl={anchorEl} onClose={cancel} sx={{ width: 400, p: 0 }}>
      <CustomLabel
        customLabelValue={field.config.customLabel}
        originalName={field.meta.caption}
        updateCustomLabel={onUpdateCustomLabel}
      />
      {isTextField && <SuppressNoValueDimensionComponent field={field} onChange={updateSuppression} />}
      {isTextField && <AggregateStringComponent field={field} onChange={updateAggregation} />}
      <FieldOptionsDivider />
      {showGroupings && (
        <>
          <DateGroupingOptionsList field={field} updateGrouping={updateGrouping} />
          <FieldOptionsDivider />
        </>
      )}
      <FieldOptionDeleteButton onRemove={handleRemove} />
    </FieldPopover>
  );
}
