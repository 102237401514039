import { ReportField, ReportType } from "../../../../../shared/reporting/api/biClient.types";

export default function canDropValueItem(reportType: ReportType | undefined, values: ReportField[]) {
  switch (reportType) {
    case ReportType.PieChart: {
      return values.length === 0;
    }
    case ReportType.BarChart:
    case ReportType.LineChart: {
      return true;
    }
    default:
      return true;
  }
}
