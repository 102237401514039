import { Grid } from "@mui/material";
import React from "react";
import ScrollableFlexContainer from "../../../../../../shared/components/ScrollableFlexContainer";
import { ReportField } from "../../../../../../shared/reporting/api/biClient.types";
import { BuilderContext } from "../../types";
import Settings from "./Settings";
import TableStyle from "./TableStyle";
import TextField from "./TextField";

export default function SettingsContainer() {
  const { rowsArea } = React.useContext(BuilderContext);

  const fieldDescriptors = React.useMemo(() => getFieldDescriptors(rowsArea.values), [rowsArea.values]);

  return (
    <ScrollableFlexContainer>
      <Grid container sx={{ gap: ".5rem", px: "1.3rem" }}>
        <Settings />
        <TableStyle />
        <Grid container sx={{ gap: ".5rem", flex: 1 }}>
          {fieldDescriptors.map((descriptor) => (
            <TextField key={descriptor.field.meta.name} field={descriptor.field} />
          ))}
        </Grid>
      </Grid>
    </ScrollableFlexContainer>
  );
}

function getFieldDescriptors(values: ReportField[]) {
  const fieldDescriptors = values
    .map((field, index) => {
      const isLeaf = index === values.length - 1;
      return { field, isLeaf };
    })
    .filter((field) => !field.isLeaf);
  return fieldDescriptors;
}
