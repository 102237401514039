import AddIcon from "@mui/icons-material/Add";
import { Button, Grid } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { DropTargetMonitor, useDrop } from "react-dnd";
import { DimensionDescriptor } from "../../../../../../shared/reporting/api/biClient.types";
import { generateGuid } from "../../../../../../shared/utilities/generateGuid";
import { FieldWithOrder } from "../../../../../hooks/FieldWithOrder";
import { HoveredFieldItemIndex, getInsertToIndex, hoveredIndexIsDefined } from "../../../utils/dragDropHelper";
import useDragLeave from "../../hooks/useDragLeave";
import ColumnItem from "./ColumnItem";
import { COLUMN_DD_ITEM_TYPE, OptionField } from "./ColumnsOptions.types";
import ColumnsPlaceHolder from "./ColumnsPlaceHolder";
import CustomDragLayer from "./CustomDragLayer";

interface Props {
  selectedFields: FieldWithOrder[];
  options: OptionField[];
  onSelectionChanged: (selection: FieldWithOrder[]) => void;
}

const ColumnsContainer = (props: Props) => {
  const { selectedFields, options, onSelectionChanged } = props;
  const [hoveredIndex, setHoveredIndex] = useState<HoveredFieldItemIndex>("draggingIsOff");
  const parentRef = useRef<HTMLDivElement | null>(null);

  const hoveredIndexRef = useRef<HoveredFieldItemIndex>(hoveredIndex);
  hoveredIndexRef.current = hoveredIndex;

  const [currentFields, setCurrentFields] = useState(selectedFields);
  useEffect(() => setCurrentFields(selectedFields), [selectedFields]);

  const dragFieldsRef = useRef(currentFields);
  dragFieldsRef.current = currentFields;

  const availableOptions = useCallback(
    (field: DimensionDescriptor) => {
      return options.filter((op) => !currentFields.some((sf) => sf.field.name === op.id) || op.id === field.name);
    },
    [options, currentFields]
  );

  const replaceFieldWith = useCallback(
    (orderField: FieldWithOrder, newField: DimensionDescriptor) => {
      const fields = [...currentFields];
      const f = fields.find((field) => field.field.name === orderField.field.name);
      if (f) {
        f.field = newField;
      }
      onSelectionChanged(fields);
    },
    [currentFields, onSelectionChanged]
  );

  const addColumn = useCallback(() => {
    const fields = [...currentFields];
    fields.push({
      field: { name: generateGuid() },
      order: dragFieldsRef.current.length,
      canBeRemoved: true,
    } as FieldWithOrder);
    onSelectionChanged(fields);
  }, [currentFields, onSelectionChanged]);

  const onRemoveField = useCallback(
    (sf: FieldWithOrder) => {
      const fields = [...currentFields];
      const index = fields.findIndex((f) => f.field.name === sf.field.name);
      fields.splice(index, 1);
      fields.forEach((field, index) => {
        field.order = index;
      });
      onSelectionChanged(fields);
    },
    [currentFields, onSelectionChanged]
  );

  const onEndReordering = useCallback(
    (draggingItem: FieldWithOrder) => {
      const toIndex = getInsertToIndex(Array.from(parentRef.current?.children || []));

      const values = [...dragFieldsRef.current];
      values.splice(draggingItem.order, 1);
      values.splice(toIndex, 0, draggingItem);
      setCurrentFields(values);
      setHoveredIndex("draggingIsOff");

      setTimeout(() => {
        dragFieldsRef.current.forEach((field, index) => {
          field.order = index;
        });
        onSelectionChanged(dragFieldsRef.current);
      }, 0);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [{ isOver, didDrop, draggingItem }, drop] = useDrop<any, any, any>(() => {
    return {
      accept: [COLUMN_DD_ITEM_TYPE],
      collect: (monitor: DropTargetMonitor) => ({
        isOver: monitor.isOver(),
        didDrop: monitor.didDrop(),
        draggingItem: monitor.getItem(),
      }),
    };
  }, []);

  const [fieldItemIndexesForShifting, setFieldItemIndexesForShifting] = useState<number[]>();
  const [draggingItemMovedFromInitialPosition, setDraggingItemMovedFromInitialPosition] = useState(false);

  const handleItemHovered = useCallback((hoveredItem: FieldWithOrder) => {
    const prevIndex = hoveredIndexRef.current;
    const newIndex = hoveredItem.order;
    if (prevIndex !== "draggingIsOff" && prevIndex !== newIndex) {
      setDraggingItemMovedFromInitialPosition(true);
    }
    setHoveredIndex(newIndex);
  }, []);

  useDragLeave(() => setHoveredIndex("draggingIsOff"), isOver, didDrop);

  useEffect(() => {
    const arr = dragFieldsRef.current
      .filter((v) => hoveredIndexIsDefined(hoveredIndex) && v.order >= hoveredIndex)
      .map((v) => v.order);
    setFieldItemIndexesForShifting(arr);
  }, [hoveredIndex]);

  useEffect(() => {
    //Dragging is over
    if (!draggingItem) {
      setFieldItemIndexesForShifting(undefined);
      setDraggingItemMovedFromInitialPosition(false);
    }
  }, [draggingItem]);

  return (
    <Grid ref={drop} container py={2} gap={1}>
      <Grid ref={parentRef} container sx={{ flexDirection: "column", px: 2, gap: 1 }}>
        {currentFields.map((item, index) => {
          const addShifting =
            fieldItemIndexesForShifting !== undefined && fieldItemIndexesForShifting.includes(item.order);
          const hideDraggingItem =
            draggingItem && item.order === draggingItem?.field?.order && fieldItemIndexesForShifting !== undefined;
          return (
            <ColumnItem
              key={item.field.name + `-${index}`}
              options={availableOptions(item.field)}
              field={item}
              draggingItemMovedFromInitialPosition={draggingItemMovedFromInitialPosition}
              onItemHovered={handleItemHovered}
              addShifting={addShifting}
              hideDraggingItem={hideDraggingItem}
              onEndReordering={onEndReordering}
              onRemove={onRemoveField}
              onReplaceWith={replaceFieldWith}
            />
          );
        })}
        <CustomDragLayer />
      </Grid>
      {isOver && <ColumnsPlaceHolder hide={fieldItemIndexesForShifting === undefined && draggingItem} />}
      <Grid item sx={{ pl: 5 }} onDragEnter={() => setHoveredIndex("nonSortableItemHovered")}>
        <Button startIcon={<AddIcon />} onClick={addColumn}>
          Add Column
        </Button>
      </Grid>
    </Grid>
  );
};

export default ColumnsContainer;
