import { Checkbox, FormControlLabel, Stack, Typography } from "@mui/material";
import { useCallback, useMemo } from "react";
import { DimensionField, FieldConfiguration } from "../../../../../../shared/reporting/api/biClient.types";
import { isFieldSupportedLogoIcon } from "./isFieldSupportedLogoIcon";

interface Props {
  field: DimensionField;
  onUpdateConfig: (field: DimensionField, changes: Partial<FieldConfiguration>) => void;
}

export default function ShowLogoIconComponent({ field, onUpdateConfig }: Props) {
  const showLogoIconOptions = useMemo(() => {
    const isRowField = isFieldSupportedLogoIcon(field);

    return isRowField;
  }, [field]);

  const updateShowLogoIcon = useCallback(
    (_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      onUpdateConfig(field, { showLogoIcon: checked });
    },
    [field, onUpdateConfig]
  );

  if (showLogoIconOptions === false) return null;

  return (
    <FormControlLabel
      key={`${field.meta.name}_logoOptions`}
      control={
        <Checkbox
          sx={{ p: "2px .4rem 2px 0" }}
          disableRipple
          disableFocusRipple
          disableTouchRipple
          checked={field.config.showLogoIcon === true}
          onChange={updateShowLogoIcon}
        />
      }
      label={
        <Stack direction={"row"} gap={0.5}>
          <Typography>Show Company Logo</Typography>
          <Typography color="secondary">(if available)</Typography>
        </Stack>
      }
    />
  );
}
