import { ListItem, SxProps, Theme } from "@mui/material";
import { PropsWithChildren, useCallback, useEffect } from "react";
import { DragSourceMonitor, useDrag } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";
import { MetaDescriptorBase } from "../../../../../shared/reporting/api/biClient.types";

interface Props<T extends MetaDescriptorBase> {
  field: T;
  sx?: SxProps<Theme>;
}

const FieldItemContainer = <T extends MetaDescriptorBase>({ field, sx, children }: PropsWithChildren<Props<T>>) => {
  const collect = useCallback(
    (monitor: DragSourceMonitor<T, unknown>) => ({
      isDragging: monitor.isDragging(),
    }),
    []
  );

  const [{ isDragging }, drag, dragPreview] = useDrag(
    () => ({
      type: field.itemType,
      item: field,
      collect,
    }),
    [field]
  );

  useEffect(() => {
    dragPreview(getEmptyImage());
  }, [dragPreview]);

  return (
    <ListItem
      role="Handle"
      ref={drag}
      sx={{
        p: "4px 0",
        "&:hover": {
          cursor: "pointer",
          backgroundColor: "#fafafa",
        },
        opacity: isDragging ? 0.5 : 1,
        ...sx,
      }}
    >
      {children}
    </ListItem>
  );
};

export default FieldItemContainer;
