import React from "react";
import {
  FieldConfiguration,
  MeasureDateRange,
  ReportField,
} from "../../../../../../shared/reporting/api/biClient.types";
import DateRanges from "../../../pivot/table/measures/DateRanges";
import { isMeasure } from "../../../utils/isMeasure";

interface Props {
  measure: ReportField;
  onClose: () => void;
  saveChanges: (changes: Partial<FieldConfiguration>, causeClosing: boolean) => void;
}

export default function PeriodMenuItem(props: Props) {
  const { measure, onClose, saveChanges } = props;
  const isFieldMeasure = isMeasure(measure);

  const updateDateRangeType = React.useCallback(
    (newDateRangeType: MeasureDateRange) => {
      if (measure.config.dateRange === newDateRangeType) {
        onClose();
      } else {
        saveChanges({ dateRange: newDateRangeType }, true);
      }
    },
    [measure, onClose, saveChanges]
  );

  if (isFieldMeasure && !measure.meta.capabilities.dateRangeCustomization) return <></>;

  return (
    <DateRanges dateRange={measure.config.dateRange ?? MeasureDateRange.EndingBalance} onChange={updateDateRangeType} />
  );
}
