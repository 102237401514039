import { Box, IconButton, ListItem, SxProps, Theme, Typography } from "@mui/material";
import { memo, useCallback, useEffect } from "react";
import { useDrag, useDrop } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";
import CloseIconButton from "../../../../../shared/components/CloseIconButton";
import { AreaItemType, Ordering } from "../../../../../shared/reporting/api/biClient.types";
import SortAscIcon from "../../../../icons/SortAscIcon";
import SortDescIcon from "../../../../icons/SortDescIcon";
import { SortField } from "../../Types";

const SortFieldItem = memo(
  (props: {
    field: SortField;
    sx?: SxProps<Theme>;
    onRemoveItem?: (field: SortField) => void;
    onChangeSorting?: (field: SortField, order: Ordering) => void;
    onEndMoveItem?: (field: SortField) => void;
    onDragMove?: (field: SortField, replaceField: SortField) => void;
  }) => {
    const { field, sx, onRemoveItem, onChangeSorting, onEndMoveItem, onDragMove } = props;
    const caption = `${field.config.caption || field.meta.caption}${field.config.isGroupField ? " (Group)" : ""}`;

    useEffect(() => {
      if (!field.config.ordering) {
        if (onChangeSorting) onChangeSorting(field, Ordering.Ascending);
      }
    }, [field, field.config.ordering, onChangeSorting]);

    const [{ isDragging }, drag, dragPreview] = useDrag(
      () => ({
        type: AreaItemType.SORTS,
        item: field,
        collect: (monitor) => ({
          isDragging: monitor.isDragging(),
        }),
        end: (item, monitor) => {
          if (monitor.didDrop()) {
            onEndMoveItem?.call(null, item);
          }
        },
        canDrag: () => !field.config.isGroupField,
      }),
      [field]
    );

    const [, drop] = useDrop(
      () => ({
        accept: AreaItemType.SORTS,
        hover(item: SortField, monitor) {
          if (monitor.canDrop() && item.meta.name !== field.meta.name) {
            onDragMove?.call(null, item, field);
          }
        },
        canDrop: (_: SortField, monitor) => monitor.isOver() && !field.config.isGroupField,
      }),
      [field]
    );

    const handleRemoveItem = useCallback(() => {
      onRemoveItem?.call(null, field);
    }, [onRemoveItem, field]);

    const revertSorting = useCallback(() => {
      if (onChangeSorting)
        onChangeSorting(
          field,
          field.config.ordering === Ordering.Descending ? Ordering.Ascending : Ordering.Descending
        );
    }, [onChangeSorting, field]);

    const setRef = useCallback(
      (node: HTMLLIElement | null) => {
        drag(drop(node));
      },
      [drag, drop]
    );

    useEffect(() => {
      dragPreview(getEmptyImage());
    }, [dragPreview]);

    return (
      <ListItem
        role="Handle"
        ref={setRef}
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: ".5rem",
          height: "32px",
          borderRadius: "2px",
          p: "5px 0",
          opacity: isDragging ? 0 : 1,
          backgroundColor: "#F9F9F9",
          ...sx,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flex: 1,
            flexDirection: "row",
            alignItems: "center",
            border: "1px solid",
            borderColor: `${field.config.isGroupField ? "action.disabled" : "rgba(33, 33, 33, 0.38)"}`,
            borderRadius: "2px",
            backgroundColor: "background.paper",
            p: "0 5px",
            "&:hover": {
              cursor: "grab",
            },
            minHeight: "32px",
          }}
        >
          <Typography
            variant="caption"
            sx={{ display: "flex", flexGrow: 1 }}
            color={(theme) => (field.config.isGroupField ? theme.palette.text.disabled : theme.palette.text.primary)}
          >
            {caption}
          </Typography>
          {!field.config.isGroupField && (
            <CloseIconButton onClick={handleRemoveItem} iconProps={{ htmlColor: "rgba(33, 33, 33, 0.38)" }} />
          )}
        </Box>
        <Box
          sx={(theme) => ({
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            border: "1px solid" + theme.palette.primary.main,
            backgroundColor: "rgba(0, 131, 143, 0.08)",
            borderRadius: "2px",
            p: 0,
            "&:hover": {
              cursor: "pointer",
            },
          })}
          onClick={revertSorting}
        >
          <IconButton>
            {field.config.ordering === Ordering.Descending && <SortDescIcon color="primary" />}
            {field.config.ordering !== Ordering.Descending && <SortAscIcon color="primary" />}
          </IconButton>
        </Box>
      </ListItem>
    );
  }
);

export default SortFieldItem;
