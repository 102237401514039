import { useRef } from "react";
import {
  AreaItemType,
  ConditionConfiguration,
  DimensionDescriptor,
  DimensionField,
  FieldConfiguration,
  Ordering,
  PivotGeneralSettings,
  ReportField,
  SortConfiguration,
} from "../../../../../shared/reporting/api/biClient.types";
import sortsSlice, {
  addSortingAction,
  moveSortingAction,
  removeSortingAction,
  removeSortingByMetaAction,
  setSortingAction,
  updateSortingAction,
  updateSortingConfigAction,
} from "../../common/hooks/fieldsState.sorts";
import { ConditionField, SortField } from "../../Types";
import fieldsStateColumnsSlice, {
  addColumnAction,
  moveColumnAction,
  removeColumnAction,
  setColumnsAction,
  updateColumnAction,
  updateColumnConfigAction,
} from "./fieldsState.columns";
import fieldsStateSlice, {
  addConditionAction,
  addMeasureAction,
  moveConditionAction,
  moveMeasureAction,
  removeConditionAction,
  removeMeasureAction,
  setConditionsAction,
  setMeasuresAction,
  updateConditionAction,
  updateConditionConfigAction,
  updateMeasureAction,
  updateMeasureConfigAction,
} from "./fieldsState.conditions";
import fieldsStateRowsSlice, {
  addRowAction,
  moveRowAction,
  removeRowAction,
  setRowsAction,
  updateRowAction,
  updateRowConfigAction,
} from "./fieldsState.rows";
import fieldsStateSettingsSlice, { setSettingsAction, updateSettingsAction } from "./fieldsState.settings";

import useReducerSafe from "../../common/hooks/useReducerSafe";

export type FieldsStateReturnType = ReturnType<typeof useFieldsState>;

export const useFieldsState = () => {
  const [state, dispatch] = useReducerSafe(fieldsStateSlice);
  const [rowsState, dispatchRows] = useReducerSafe(fieldsStateRowsSlice);
  const [columnsState, dispatchColumns] = useReducerSafe(fieldsStateColumnsSlice);
  const [sortingState, dispatchSorting] = useReducerSafe(sortsSlice);
  const [settingsState, dispatchSettings] = useReducerSafe(fieldsStateSettingsSlice);

  const conditionsRef = useRef(state.conditions);
  conditionsRef.current = state.conditions;

  //CONDITIONS
  const setConditions = (fields: ConditionField[]) => dispatch(setConditionsAction(fields));

  const addCondition = (field: ConditionField, index: number) => {
    dispatch(addConditionAction({ field, index: index }));
  };

  const removeCondition = (field: ConditionField) => dispatch(removeConditionAction(field));

  const moveCondition = (field: ConditionField, newIndex: number) => dispatch(moveConditionAction({ field, newIndex }));

  const updateCondition = (field: ConditionField, changes: Partial<ConditionField>) =>
    dispatch(updateConditionAction({ field, changes }));

  const updateConditionConfig = (field: ConditionField, changes: Partial<ConditionConfiguration>) =>
    dispatch(updateConditionConfigAction({ field, changes }));

  //MEASURES
  const setMeasures = (fields: ReportField[]) => dispatch(setMeasuresAction(fields));

  const addMeasure = (field: ReportField, index: number) => {
    dispatch(addMeasureAction({ field, index: index }));
  };

  const removeMeasure = (field: ReportField) => dispatch(removeMeasureAction(field));

  const moveMeasure = (field: ReportField, newIndex: number) => dispatch(moveMeasureAction({ field, newIndex }));

  const updateMeasure = (field: ReportField, changes: Partial<ReportField>) =>
    dispatch(updateMeasureAction({ field, changes }));

  const updateMeasureConfig = (field: ReportField, changes: Partial<FieldConfiguration>) =>
    dispatch(updateMeasureConfigAction({ field, changes }));

  //ROWS
  const setRows = (fields: DimensionField[]) => {
    dispatchRows(setRowsAction(fields));
  };

  const addRow = (field: DimensionField, index: number) => {
    dispatchRows(addRowAction({ field, index: index }));
  };

  const removeRow = (field: DimensionField) => {
    dispatchRows(removeRowAction(field));
  };

  const moveRow = (field: DimensionField, newIndex: number) => {
    dispatchRows(moveRowAction({ field, newIndex }));
  };

  const updateRow = (field: DimensionField, changes: Partial<DimensionField>) => {
    dispatchRows(updateRowAction({ field, changes }));
  };

  const updateRowConfig = (field: DimensionField, changes: Partial<FieldConfiguration>) => {
    dispatchRows(updateRowConfigAction({ field, changes }));
    if (field.config?.customLabel !== changes.customLabel) {
      updateSortingCaption(field.meta.name, changes.customLabel);
    }
  };

  //COLUMNS
  const setColumns = (fields: DimensionField[]) => {
    dispatchColumns(setColumnsAction(fields));
  };

  const addColumn = (field: DimensionField, index: number) => {
    dispatchColumns(addColumnAction({ field, index: index }));
  };

  const removeColumn = (field: DimensionField) => {
    dispatchColumns(removeColumnAction(field));
  };

  const moveColumn = (field: DimensionField, newIndex: number) => {
    return dispatchColumns(moveColumnAction({ field, newIndex }));
  };

  const updateColumn = (field: DimensionField, changes: Partial<DimensionField>) => {
    dispatchColumns(updateColumnAction({ field, changes }));
  };

  const updateColumnConfig = (field: ReportField, changes: Partial<FieldConfiguration>) => {
    dispatchColumns(updateColumnConfigAction({ field, changes }));
    if (field.config?.customLabel !== changes.customLabel) {
      updateSortingCaption(field.meta.name, changes.customLabel);
    }
  };

  const updateSortingCaption = (metaName: string, caption: string | undefined | null) => {
    const sortingField = sortingState.sorts.find((s) => s.meta.name === metaName);
    if (sortingField) {
      updateSortingConfig(sortingField, { caption: caption });
    }
  };

  //SORTING
  const setSorting = (fields: SortField[]) => dispatchSorting(setSortingAction({ fields, groupMetaNames: [] }));

  const addSorting = (field: DimensionField) => {
    const conditionField: SortField = {
      meta: field.meta,
      config: {
        name: field.meta.name,
        ordering: Ordering.Ascending,
        caption: field.config.customLabel || field.meta.caption,
      },
      areaItemType: AreaItemType.SORTS,
    };
    dispatchSorting(addSortingAction(conditionField));
  };

  const removeSorting = (field: SortField) => dispatchSorting(removeSortingAction(field));

  const removeSortingByMeta = (field: DimensionDescriptor) => dispatchSorting(removeSortingByMetaAction(field));

  const moveSorting = (field: SortField, newIndex: number) => dispatchSorting(moveSortingAction({ field, newIndex }));

  const updateSorting = (field: SortField, changes: Partial<SortField>) =>
    dispatchSorting(updateSortingAction({ field, changes }));

  const updateSortingConfig = (field: SortField, changes: Partial<SortConfiguration>) =>
    dispatchSorting(updateSortingConfigAction({ field, changes }));

  //SETTINGS
  const updateSettings = (changes: Partial<PivotGeneralSettings>) => dispatchSettings(updateSettingsAction(changes));
  const setSettings = (settings: PivotGeneralSettings) => dispatchSettings(setSettingsAction(settings));

  return {
    ...state,
    ...rowsState,
    ...columnsState,
    ...sortingState,
    ...settingsState,

    setConditions,
    addCondition,
    updateCondition,
    updateConditionConfig,
    removeCondition,
    moveCondition,

    setMeasures,
    addMeasure,
    removeMeasure,
    moveMeasure,
    updateMeasure,
    updateMeasureConfig,

    setRows,
    addRow,
    removeRow,
    moveRow,
    updateRow,
    updateRowConfig,

    setColumns,
    addColumn,
    removeColumn,
    moveColumn,
    updateColumn,
    updateColumnConfig,

    setSorting,
    addSorting,
    removeSorting,
    removeSortingByMeta,
    moveSorting,
    updateSorting,
    updateSortingConfig,

    updateSettings,
    setSettings,
  };
};

export default useFieldsState;
