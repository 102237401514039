import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { MetaDescriptorBase, MetaItemType } from "../../shared/reporting/api/biClient.types";
import biClient from "../api/biApi";
import { metaDataActions } from "../store/metaDataSlice";

export default function useMetaData(options: { includeDictionaries: boolean; configurationDimensions?: string[] }) {
  const dispatch = useDispatch();

  const loadMetaData = useCallback(() => {
    const load = async () => {
      try {
        const response = await biClient.loadMeta(!options.includeDictionaries);
        if (response.success) {
          dispatch(
            metaDataActions.update({
              dimensionsStructure: response.data.dimensionsStructure,
              measuresStructure: response.data.measuresStructure,
              dimensions: setItemType(response.data.dimensions, MetaItemType.DIMENSION),
              allMeasures: setItemType(response.data.measures, MetaItemType.MEASURE),
              functions: response.data.functions,
              measureGroups: response.data.measureGroups,
              loaded: true,
              loadingFailed: false,
            })
          );
        } else {
          dispatch(metaDataActions.update({ loadingFailed: true, loaded: false }));
        }
      } catch (error) {
        dispatch(metaDataActions.update({ loadingFailed: true, loaded: false }));
      }
    };
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { loadMetaData };
}

function setItemType<T extends MetaDescriptorBase>(objs: T[], itemType: MetaItemType) {
  objs.forEach((obj) => (obj.itemType = itemType));
  return objs;
}
