import {
  DimensionField,
  DimensionFieldType,
  MeasureField,
  ReportField,
} from "../../../../shared/reporting/api/biClient.types";

export const isMeasure = (field: ReportField | undefined | null): field is MeasureField => {
  if (field === undefined || field === null) {
    return false;
  }
  return field.type === DimensionFieldType.MEASURE;
};

export const isDimension = (field: ReportField | undefined | null): field is DimensionField => {
  if (field === undefined || field === null) {
    return false;
  }
  return field.type === DimensionFieldType.DIMENSION;
};

export const getMeasures = (fields: ReportField[]): MeasureField[] => {
  return fields.filter(isMeasure);
};

export const getDimensions = (fields: ReportField[]): DimensionField[] => {
  return fields.filter(isDimension);
};
