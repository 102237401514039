import { DimensionDescriptor } from "../../../../../../shared/reporting/api/biClient.types";

export interface OptionField {
  id: string;
  label: string;
  group: string;
  field: DimensionDescriptor;
}
export const COLUMN_DD_ITEM_TYPE = "column";
export const SORTING_COLUMN_DD_ITEM_TYPE = "sorting-column";
