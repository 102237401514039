import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { DimensionField, FieldConfiguration, ReportField } from "../../../../../shared/reporting/api/biClient.types";
import { insertItemAt } from "../../../../utilities/Utilities";
import { updateItemV2 } from "../../common/utilities/fieldsState";

export type FieldsState = {
  columns: DimensionField[];
};

const initialColumnsState: FieldsState = { columns: [] };

const fieldsStateColumnsSlice = createSlice({
  name: "fieldsStateColumns",
  initialState: initialColumnsState,
  reducers: {
    setColumnsAction: (state, action: PayloadAction<DimensionField[]>) => {
      state.columns = action.payload;
    },
    addColumnAction: (state, action: PayloadAction<{ field: DimensionField; index: number }>) => {
      state.columns = insertItemAt(state.columns, action.payload.field, action.payload.index);
    },
    removeColumnAction: (state, action: PayloadAction<DimensionField>) => {
      state.columns = state.columns.filter((column) => column.meta.name !== action.payload.meta.name);
    },
    moveColumnAction: (state, action: PayloadAction<{ field: DimensionField; newIndex: number }>) => {
      const { field, newIndex } = action.payload;
      const oldIndex = state.columns.findIndex((col) => col.meta.name === field.meta.name);
      if (oldIndex === -1) {
        return;
      }
      const copy = [...state.columns];
      copy.splice(oldIndex, 1);
      copy.splice(newIndex, 0, field);
      state.columns = copy;
    },
    updateColumnAction: (state, action: PayloadAction<{ field: DimensionField; changes: Partial<DimensionField> }>) => {
      state.columns = updateItemV2(action.payload.field, action.payload.changes, state.columns);
    },
    updateColumnConfigAction: (
      state,
      action: PayloadAction<{ field: ReportField; changes: Partial<FieldConfiguration> }>
    ) => {
      const column = state.columns.find((column) => column.meta.name === action.payload.field.meta.name);
      if (column) {
        const index = state.columns.indexOf(column);
        column.config = { ...column.config, ...action.payload.changes };
        state.columns[index] = column;
      }
    },
  },
});

export default fieldsStateColumnsSlice;

export const {
  setColumnsAction,
  addColumnAction,
  removeColumnAction,
  moveColumnAction,
  updateColumnAction,
  updateColumnConfigAction,
} = fieldsStateColumnsSlice.actions;
